/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as toast from "../../wrapper/toast";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import * as erros from "../../constants/stringConstants";
import * as storageConstants from "../../constants/storageConstants";
import * as ApiHelper from "../../services/apiHelper";
import * as commonServices from "../../services/common";
import { APP_NAME } from "../../constants/storageConstants";
import { LOCATION_THRESHOLD } from "../../constants/storageConstants";
import {
  NO_REGISTRATION_CODE,
  SUCCESS_CODE,
} from "../../constants/responseCodes";
import ModalContactDetails from "../Modals/ModalContactDetails";
import { BUTTON_TEXT_OK } from "../../constants/stringConstants";
import { sortArrayByName } from "../../utils/arrayUtils";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { COUNTRY } from "../../constants/storageConstants";

import EmailVerification from "./EmailVerification";
import ResumeUpload from "./ResumeUpload";
import { updateHeaderMessageIcon } from "../../utils/messageUtil";
import { READ, UNREAD } from "../../constants/messageConstants";
import { SOMETHING_WENT_WRONG } from "../../constants/stringConstants";
import { isLoggedIn } from "../../utils/authUtil";
import arrowLeft from "../../Assets/svgs/arrow-left.svg";
import { MaxWidthContainer } from "../Common/MaxWidthContainer";
import { Accordion, Card } from "react-bootstrap";
import { AccordionButton } from "react-bootstrap";
// Accordian Assets
import puzzle from "../../Assets/images/puzzle.png";

import skillMatch from "../../Assets/images/Auth/skillMatch.png";
import aiDrivenJobs from "../../Assets/images/Auth/aiDrivenJob.png";
import freeMultiUser from "../../Assets/images/Auth/freeMultiUser.png";
import freeJobPosting from "../../Assets/images/Auth/freeJobPosting.png";
import payAsYouGo from "../../Assets/images/Auth/payAsYouGo.png";

import aiDrivenJobsIcon from "../../Assets/images/Auth/aiDrivenJobIcon.png";
import freeMultiUserIcon from "../../Assets/images/Auth/freeMultiUserIcon.png";
import freeJobPostingIcon from "../../Assets/images/Auth/freeJobPostingIcon.png";
import payAsYouGoIcon from "../../Assets/images/Auth/payAsYouGoIcon.png";
import { checkUrl } from "../../utils/validation";
import AppModal from "../Modals/AppModal";
import { TermsContent } from "../Terms/Terms";
import {
  trackCandidateOnboarding,
  trackCompanyOnboarding,
  trackEvaluatorOnboarding,
} from "../../helpers/analytics";
import { analyticsConstant } from "../../constants/analyticsConstant";
import Input from "../_widgets/Input/Input";
import Button from "../../components/_widgets/Button/Button";
import Dropdown from "../_widgets/Dropdown/Dropdown";
import RegisterV2 from "./RegisterV2";
import Text from "../_widgets/Text/Text";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { initiateFirebase } from "../../config/firebase";
import Checkbox from "../_widgets/Checkbox/Checkbox";
import { isNullOrEmpty } from "../../utils/stringUtils";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

var accordianData = [
  {
    title: "Skill Match",
    description:
      "Are you still looking through heaps of resume to get that perfect candidate? At TrueTalent we offer you an industry first, TrueTalent Smart Search with precise matching. Saves you time and money!",
    icon: puzzle,
    image: skillMatch,
  },
  {
    title: "AI Driven JD Creation",
    description:
      "Do not have a JD? Fret not! In just 2 minutes, TrueTalent assists you in creating a well-articulated and thorough JD for each position you have.",
    icon: aiDrivenJobsIcon,
    image: aiDrivenJobs,
  },
  {
    title: "Free Multi-user Access",
    description:
      "Why pay additional charges to access the same database for each recruiter? TrueTalent lets you create multiple user accesses without any additional charge.",
    icon: freeMultiUserIcon,
    image: freeMultiUser,
  },
  {
    title: "Unlimited Free Job Posting",
    description:
      "Why spend large sums of money for job posting, without the surety of getting applicants? TrueTalent has a zero charge for your job postings. Go ahead and post all your open positions.",
    icon: freeJobPostingIcon,
    image: freeJobPosting,
  },
  {
    title: "Pay-as-you-go",
    description:
      "Are you paying for a service that you never use? We do not sell packages unless you need them. All our offerings come with a Pay-as-you-go model; on TrueTalent you pay for only what you need.",
    icon: payAsYouGoIcon,
    image: payAsYouGo,
  },
];

export default function Register({
  isGoogleLogin,
  isHeaderUpdate,
  setIsHeaderUpdate,
  registerReset,
}) {
  const { state } = useLocation();
  var url_string = window.location.href;
  var url = new URL(url_string);
  var referral_id = url.searchParams.get("id");
  var referral_user_id = url.searchParams.get("refId");
  var code = url.searchParams.get("code");
  var audience = url.searchParams.get("audience");
  var verifyEmail = url.searchParams.get("email");

  const [referralCode, setReferralCode] = useState(
    code && (isGoogleLogin === undefined || !isGoogleLogin) ? code : ""
  );

  let [loading, setLoading] = useState(false);
  // const [autofillLoading, setAutofillLoading] = useState(false);
  let [color] = useState("#000000");
  const locationHref = useLocation();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [fname, setFname] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState(
    verifyEmail !== undefined ? verifyEmail : ""
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState();
  const [confirm_password, setConfirm_password] = useState();
  const [company_name, setCompany_name] = useState();
  const [website, setWebsite] = useState();
  const [location, setLocation] = useState();
  const [company_size, setCompany_size] = useState();
  const [industry_domain, setIndustry_domain] = useState();
  const [locSuggestion, setLocSuggestion] = useState([]);
  const [locationId, setLocationId] = useState();

  const [showModalError, setShowModalError] = useState(false);
  const [modalErrorData, setModalErrorData] = useState();
  const [registerSection, setRegisterSection] = useState(
    audience !== null ? "form" : "select_profile"
  );
  const [selectedProfile, setSelectedProfile] = useState(
    audience !== null
      ? audience === "candidates"
        ? "candidate"
        : audience === "companies"
          ? "employer"
          : ""
      : ""
  );
  const [currentStep, setCurrentStep] = React.useState(state?.currentStep || 0);
  const [showParserModal, setShowParserModal] = React.useState(
    state?.showParserModal || false
  );
  const [profile, setProfile] = React.useState(state?.profile || {});

  const onChangeHandler_location = (text) => {
    if (text.length > LOCATION_THRESHOLD) {
      commonServices.getLocations(text).then((locations) => {
        setLocSuggestion(locations);
      });
    } else {
      setLocSuggestion([]);
    }
  };

  useEffect(() => {
    if (state && state?.selectedProfile) {
      setSelectedProfile(state?.selectedProfile);
    }
  }, [state]);

  const back = () => {
    navigate("/?auth=register");
  };

  const formData = new FormData();
  const CompanyData = new FormData();

  formData.append("email", email);
  formData.append("password", password);
  formData.append("first_name", fname);
  formData.append("last_name", last_name);
  formData.append("confirm_password", confirm_password);
  formData.append("referral_id", referral_id);
  formData.append("referralCode", referralCode);
  formData.append("referral_user_id", referral_user_id);
  let tempPhoneNumber = null;
  let tempCCPhoneNumber = null;
  if (!isNullOrEmpty(phoneNumber)) {
    const phoneNumberString = parsePhoneNumber(phoneNumber.toString());
    if (phoneNumberString !== undefined) {
      tempPhoneNumber = phoneNumberString.nationalNumber;
      tempCCPhoneNumber = phoneNumberString.countryCallingCode;
    }
  }
  CompanyData.append("phone_number", tempPhoneNumber);
  CompanyData.append("cc_phone_number", "+" + tempCCPhoneNumber);

  CompanyData.append("email", email);
  CompanyData.append("password", password);
  CompanyData.append("first_name", fname);
  CompanyData.append("last_name", last_name);
  CompanyData.append("confirm_password", confirm_password);
  CompanyData.append("company_name", company_name);
  CompanyData.append("type", "hiring-company");
  CompanyData.append("website", website);
  CompanyData.append("location", locationId);
  CompanyData.append("company_size", company_size);
  CompanyData.append("industry_domain", industry_domain);
  CompanyData.append("referral_id", referral_id);
  CompanyData.append("referralCode", referralCode);
  CompanyData.append("referral_user_id", referral_user_id);

  const masterData = commonServices.getLocalData(storageConstants.DATA);

  const isWebsiteValid = (website) => {
    return checkUrl(website) ? !website.includes("@") : false;
  };

  const validate = () => {
    var isError = 0;

    if (!fname) {
      isError = 1;
      toast.warn(erros.FIRST_NAME_REQUIRED);
      trackCompanyOnboarding(
        analyticsConstant.VALIDATION_ERROR,
        erros.FIRST_NAME_REQUIRED
      );
    } else if (!last_name) {
      isError = 1;
      toast.warn(erros.LAST_NAME_REQUIRED);
      trackCompanyOnboarding(
        analyticsConstant.VALIDATION_ERROR,
        erros.LAST_NAME_REQUIRED
      );
    } else if (!email) {
      isError = 1;
      toast.warn(erros.EMAIL_REQUIRED);
      trackCompanyOnboarding(
        analyticsConstant.VALIDATION_ERROR,
        erros.EMAIL_REQUIRED
      );
    } else if (!commonServices.checkEmailValid(email)) {
      isError = 1;
      toast.warn(erros.INCORRECT_EMAIL);
      trackCompanyOnboarding(
        analyticsConstant.VALIDATION_ERROR,
        erros.INCORRECT_EMAIL
      );
    } else if (!phoneNumber) {
      isError = 1;
      toast.warn(erros.PHONE_NUMBER_REQUIRED);
      trackCompanyOnboarding(analyticsConstant.VALIDATION_ERROR, erros.PHONE_NUMBER_REQUIRED);
    } else if (phoneNumber &&
      phoneNumber !== "" &&
      !isValidPhoneNumber(phoneNumber)) {
      isError = 1;
      toast.warn(erros.PHONE_NUMBER_INCORRECT);
      trackCompanyOnboarding(
        analyticsConstant.VALIDATION_ERROR,
        erros.PHONE_NUMBER_INCORRECT
      );
    } else if (!password) {
      isError = 1;
      toast.warn(erros.PASSWORD_REQUIRED);
      trackCompanyOnboarding(
        analyticsConstant.VALIDATION_ERROR,
        erros.PASSWORD_REQUIRED
      );
    } else if (!commonServices.isValidPassword(password)) {
      isError = 1;
      toast.warn(erros.INVALID_PASSWORD);
      trackCompanyOnboarding(
        analyticsConstant.VALIDATION_ERROR,
        erros.INVALID_PASSWORD
      );
    } else if (!confirm_password) {
      isError = 1;
      toast.warn(erros.CONFIRM_PASSWORD_REQUIRED);
      trackCompanyOnboarding(
        analyticsConstant.VALIDATION_ERROR,
        erros.CONFIRM_PASSWORD_REQUIRED
      );
    } else if (!company_name) {
      isError = 1;
      toast.warn(erros.COMPANY_NAME_REQUIRED);
      trackCompanyOnboarding(
        analyticsConstant.VALIDATION_ERROR,
        erros.COMPANY_NAME_REQUIRED
      );
    } else if (!website) {
      isError = 1;
      toast.warn(erros.WEBSITE_REQUIRED);
      trackCompanyOnboarding(
        analyticsConstant.VALIDATION_ERROR,
        erros.WEBSITE_REQUIRED
      );
    } else if (!isWebsiteValid(website.toLowerCase())) {
      isError = 1;
      toast.warn(erros.VALID_DOMAIN_REQUIRED);
      trackCompanyOnboarding(
        analyticsConstant.VALIDATION_ERROR,
        erros.VALID_DOMAIN_REQUIRED
      );
    } else if (!locationId) {
      isError = 1;
      toast.warn(erros.COMPANY_LOCATION_REQUIRED);
      trackCompanyOnboarding(
        analyticsConstant.VALIDATION_ERROR,
        erros.COMPANY_LOCATION_REQUIRED
      );
    } else if (!company_size) {
      isError = 1;
      toast.warn(erros.COMPANY_SIZE_REQUIRED);
      trackCompanyOnboarding(
        analyticsConstant.VALIDATION_ERROR,
        erros.COMPANY_SIZE_REQUIRED
      );
    } else if (!industry_domain) {
      isError = 1;
      toast.warn(erros.COMPANY_INDUSTRY_DOMAIN_REQUIRED);
      trackCompanyOnboarding(
        analyticsConstant.VALIDATION_ERROR,
        erros.COMPANY_INDUSTRY_DOMAIN_REQUIRED
      );
    }

    if (email && website) {
      var website_name = website;
      if (
        !website_name.includes("https://") &&
        !website_name.includes("http://")
      ) {
        website_name = "https://" + website_name;
      }

      try {
      } catch (e) {
        isError = 1;
        toast.warn(erros.VALID_WEBSITE_URL);
        trackCompanyOnboarding(
          analyticsConstant.VALIDATION_ERROR,
          erros.VALID_WEBSITE_URL
        );
      }
    }

    return isError;
  };
  const changeCompany = async (e) => {
    e.preventDefault();

    setTncIsOpen(false);

    if (!Boolean(validate())) {
      setLoading(true);

      await ApiHelper.companyRegister(CompanyData).then((response) => {
        if (response.isSuccess === true) {
          trackCompanyOnboarding(analyticsConstant.COMPLETE, "EMAIL VERIFY");
          setLoading(false);
          setRegisterSection("email_verify");
        } else {
          setLoading(false);
          if (response.code === NO_REGISTRATION_CODE) {
            setShowModalError(true);
            setModalErrorData({
              ...modalErrorData,
              heading: erros.COMPANY_REGISTRATION_ISSUE,
              paragraph: response.message,
              buttonText: BUTTON_TEXT_OK,
            });
            trackCompanyOnboarding(analyticsConstant.FAILED, response.message);
          } else if (response.code === SUCCESS_CODE) {
            setRegisterSection("email_verify");
            setRegisterSection(analyticsConstant.COMPLETE, "EMAIL VERIFY");
          } else {
            toast.warn(response.message);
            trackCompanyOnboarding(analyticsConstant.FAILED, response.message);
          }
        }
      });
    }
  };

  const getGoogleLogin = (e) => {
    e.preventDefault();

    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;

        const data = {
          first_name: user.displayName.split(" ")[0],
          last_name: user.displayName.split(" ")[1],
          email: user.email,
          provider_id: user.providerId,
          uid: user.uid,
          token: user.accessToken,
        };

        setLoading(true);
        ApiHelper.googleLoginV2(data).then((response) => {
          setLoading(false);

          if (response.isSuccess === true) {
            if (response.data !== null) {
              var redirect = "/";
              if (response.data.profile.is_profile_completed) {
                toast.success(response.message);
                commonServices.storeLocalData(
                  storageConstants.AUTH,
                  response.data.token
                );
                commonServices.storeLocalData(
                  storageConstants.USER_ROLE,
                  response.data.profile.role_type
                );
                commonServices.storeLocalData(
                  storageConstants.PROFILE,
                  response.data.profile
                );
                setIsHeaderUpdate(!isHeaderUpdate);
                setIsHeaderUpdate(!isHeaderUpdate);
                if (response?.data?.user_unread_messages > 0) {
                  updateHeaderMessageIcon(UNREAD);
                } else {
                  updateHeaderMessageIcon(READ);
                }
              } else if (!response.data.profile.is_profile_completed) {
                setSelectedProfile("candidate");
                setProfile(response.data.profile);
                setCurrentStep(1);
                setShowParserModal(true);
                return;
              } else if (!response.data.profile.email_verified_at) {
                if (
                  response.data.profile.role_type === "CA" ||
                  response.data.profile.role_type === "CU"
                ) {
                  navigate("/register?email=" + response.data.profile.email);
                  return;
                }
                setCurrentStep(2);
                setShowStep(true);

                navigate("/onboarding", {
                  state: {
                    currentStep: 2,
                    profile: response.data.profile,
                    isHeaderUpdate: isHeaderUpdate,
                    showStep: true,
                    showModalApplyJob,
                    referredData,
                  },
                });
                return;
              }

              if (
                response.data.token &&
                (response.data.profile.role_type === "CD" ||
                  response.data.profile.role_type === "EV") &&
                response.data.profile !== null &&
                response.data.profile.progress < 100
              ) {
                redirect = "/workprofile?profileInProgress=1";
              } else if (
                response.data.token &&
                response.data.profile.role_type === "EV" &&
                response.data.profile !== null &&
                response.data.profile.progress >= 100
              ) {
                redirect = "/evaluator/dashboard";
              } else if (
                response.data.token &&
                response.data.profile.role_type === "CA"
              ) {
                redirect = "/company-dashboard";
              } else if (
                response.data.token &&
                response.data.profile.role_type === "PA"
              ) {
                redirect = "/company/edit";
              }
              if (redirect !== null) {
                navigate(redirect);
              } else {
                navigate("/", {
                  state: {
                    nextStep: response.data.next_step,
                    message: response.data.message,
                  },
                });
              }
            }
          } else {
            if (response.code === 203) {
              setShowModalRestore(!showModalRestore);
            } else if (response.code === 201) {
              setShowModalError(true);
              setModalErrorData({
                ...modalErrorData,
                heading: "Account Deleted",
                paragraph: response.message,
                buttonText: BUTTON_TEXT_OK,
              });
            } else {
              toast.warn(response.message);
              if (
                response.data !== undefined &&
                (response.data.length > 0 || response.data.length === undefined)
              ) {
                if (response.data.show_email_screen) {
                  if (
                    response.data.profile.role_type === "CA" ||
                    response.data.profile.role_type === "CU"
                  ) {
                    navigate("/register?email=" + response.data.profile.email);
                    return;
                  }
                  setCurrentStep(3);
                  setShowStep(true);

                  navigate("/onboarding", {
                    state: {
                      currentStep: 3,
                      profile: response.data.profile,
                      isHeaderUpdate: isHeaderUpdate,
                      showStep: true,
                      showModalApplyJob,
                      referredData,
                    },
                  });
                  return;
                } else if (!response.data.is_profile_completed) {
                  setCurrentStep(1);
                  setShowStep(true);

                  navigate("/onboarding", {
                    state: {
                      currentStep: 1,
                      selectedProfile:
                        response?.data?.role_type === "EV"
                          ? "Evaluator"
                          : "Candidate",
                      profile: response.data.profile,
                      isHeaderUpdate: isHeaderUpdate,
                      showStep: true,
                      showModalApplyJob,
                      referredData,
                    },
                  });
                  return;
                } else if (!response.data.profile.email_verified_at) {
                  navigate("/register?email=" + response.data.profile.email);
                  return;
                } else if (!response.data.is_mobile_verified) {
                  setCurrentStep(3);
                  setShowStep(true);

                  navigate("/onboarding", {
                    state: {
                      currentStep: 3,
                      profile: response.data.profile,
                      isHeaderUpdate: isHeaderUpdate,
                      showStep: true,
                      showModalApplyJob,
                      referredData,
                    },
                  });
                  return;
                }
              }
            }
          }
        });
      })
      .catch((error) => {
        toast.warn(error.message);
      });
  };

  const resendVerificationEmail = () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("email", email);
    ApiHelper.resendVerificationEmail(formData).then((response) => {
      if (response.isSuccess === true) {
        toast.success(response.message);
        setLoading(false);
      } else {
        setLoading(false);
        toast.warn(response.message);
      }
    });
  };

  const changeRoute = () => {
    navigate("/login");
  };

  const showRegistrationForm = () => {
    if (selectedProfile !== "") {
      setRegisterSection("form");
    }
  };

  useEffect(() => {
    setProfile({
      ...profile,
      selectedProfile: selectedProfile,
    });

    showRegistrationForm();

    if (selectedProfile === "candidate") {
      accordianData = [
        {
          title: "Job Match",
          description:
            "Are you browsing through 100s of job listings to find the one that you wish to apply. At TrueTalent we ensure your primary skills are matched automatically against each job that’s listed on the platform and you get to choose the best ones to apply.",
          icon: puzzle,
          image: skillMatch,
        },
        {
          title: "Smart Job Apply",
          description:
            "Let TrueTalent’s AI tool do the job for you. We understand the efforts you need to apply for each job. So, we put an TrueTalent AI that will apply for jobs on your behalf. Not just apply but apply for the relevant jobs, so your resume gets seen by all the right recruiters.",
          icon: aiDrivenJobsIcon,
          image: aiDrivenJobs,
        },
        {
          title: "Recruiter Connect",
          description:
            "We know you always wanted to reach out to the recruiters before applying for jobs, since you deserve to know more before you send out your resume. At TrueTalent with the Pro feature you can directly connect with the recruiters.",
          icon: freeMultiUserIcon,
          image: freeMultiUser,
        },
        {
          title: "Pre-Validated",
          description:
            "Want to skip first round of evaluation in multiple companies and get a direct meeting with the decision maker. Get pre-validated by an industry expert and get that perfect rating, which elevates your candidature with the hiring companies. Get hired faster and get better offers.",
          icon: freeJobPostingIcon,
          image: freeJobPosting,
        },
      ];
    } else if (selectedProfile === "evaluator") {
      accordianData = [
        {
          title: "Un-ending Earning Potential",
          description:
            "We value your experience and expertise and we believe it should not be limited to only a fixed job. Whether you are a full-time employee or you work as a freelancer, if you have more than 8 years of experience in the industry, we seek your expertise in validating job seekers for their core skills. And we pay you the industry’s best! And what more, we let you decide how many hours and how many days you wish to put in from your core work to be an Expert Evaluator at TrueTalent.",
          icon: puzzle,
          image: skillMatch,
        },
        {
          title: "Be a Mentor",
          description:
            "Being a mentor is not just noble but pays handsomely. Be part of an elite team that guides, mentors and takes the next generation of job-seekers to the next level in their career journey. At TrueTalent we offer you a great platform to choose between being a mentor to many or just a few elite one, as per your choice.",
          icon: aiDrivenJobsIcon,
          image: aiDrivenJobs,
        },
      ];
    } else {
      accordianData = [
        {
          title: "Skill Match",
          description:
            "Are you still looking through heaps of resume to get that perfect candidate? At TrueTalent we offer you an industry first, TrueTalent Smart Search with precise matching. Saves you time and money!",
          icon: puzzle,
          image: skillMatch,
        },
        {
          title: "AI Driven JD Creation",
          description:
            "Do not have a JD? Fret not! In just 2 minutes, TrueTalent assists you in creating a well-articulated and thorough JD for each position you have.",
          icon: aiDrivenJobsIcon,
          image: aiDrivenJobs,
        },
        {
          title: "Free Multi-user Access",
          description:
            "Why pay additional charges to access the same database for each recruiter? TrueTalent lets you create multiple user accesses without any additional charge.",
          icon: freeMultiUserIcon,
          image: freeMultiUser,
        },
        {
          title: "Unlimited Free Job Posting",
          description:
            "Why spend large sums of money for job posting, without the surety of getting applicants? TrueTalent has a zero charge for your job postings. Go ahead and post all your open positions.",
          icon: freeJobPostingIcon,
          image: freeJobPosting,
        },
        {
          title: "Pay-as-you-go",
          description:
            "Are you paying for a service that you never use? We do not sell packages unless you need them. All our offerings come with a Pay-as-you-go model; on TrueTalent you pay for only what you need.",
          icon: payAsYouGoIcon,
          image: payAsYouGo,
        },
      ];
    }
  }, [selectedProfile]);

  useEffect(() => {
    if (registerReset) {
      setCurrentStep(0);
      state && state?.selectedProfile
        ? setSelectedProfile(state?.selectedProfile)
        : setSelectedProfile("");
      setRegisterSection("form");
    }
  }, [registerReset]);

  const normalizePhoneNumber1 = (input, defaultCountry = null) => {
    const parsedPhone = parsePhoneNumber(input, defaultCountry);
    if (parsedPhone) {
      return parsedPhone.number;
    } else {
      return input;
    }
  };

  const googleCallback = () => {
    if (!loading) {
      let url = "auth/google/callback" + locationHref.search;
      setLoading(true);
      ApiHelper.googleLoginCallback(url).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {
          setProfile(response.data.profile);
          if (
            response.data !== null &&
            response.data.is_user_exists &&
            response.data.is_profile_completed &&
            response.data.is_mobile_verified
          ) {
            commonServices.storeLocalData(
              storageConstants.AUTH,
              response.data.token
            );
            commonServices.storeLocalData(
              storageConstants.USER_ROLE,
              response.data.role_type
            );
            commonServices.storeLocalData(
              storageConstants.PROFILE,
              response.data.profile
            );
            setIsHeaderUpdate(!isHeaderUpdate);
            let redirect = "/";
            if (response?.data?.user_unread_messages > 0) {
              updateHeaderMessageIcon(UNREAD);
            } else {
              updateHeaderMessageIcon(READ);
            }
            setSelectedProfile("candidate");
            if (!response.data.is_profile_completed) {
              setCurrentStep(1);
              return;
            } else if (!response.data.is_mobile_verified) {
              setCurrentStep(3);
              return;
            } else if (
              response.data.token &&
              (response.data.role_type === "CD" ||
                response.data.role_type === "EV") &&
              response.data.profile !== null &&
              response.data.profile.progress < 100
            ) {
              setCurrentStep(4);
              return;
            } else if (
              response.data.token &&
              response.data.role_type === "CD" &&
              response.data.profile !== null &&
              response.data.profile.progress < 100
            ) {
              redirect = "/workprofile?profileInProgress=1";
            }

            if (redirect !== null) {
              navigate(redirect);
            } else {
              navigate("/");
            }
          } else {
            setSelectedProfile("candidate");
            if (!response.data.is_profile_completed) {
              setCurrentStep(1);
            } else if (!response.data.is_mobile_verified) {
              setCurrentStep(3);
            }
          }
        } else if (
          response.message !== "" &&
          response.message !== SOMETHING_WENT_WRONG
        ) {
          toast.warn(response.message);
          navigate("/login");
        }
      });
    }
  };

  useEffect(() => {
    initiateFirebase();
    if (isLoggedIn()) {
      navigate("/");
    }
    if (isGoogleLogin !== undefined && isGoogleLogin) {
      googleCallback();
    }
  }, []);

  const [step, setStep] = useState(1);
  const [activeAccordian, setActiveAccordian] = useState(false);

  useEffect(() => {
    if (!activeAccordian) {
      const interval = setInterval(() => {
        if (step >= accordianData.length) {
          setStep(1);
        } else {
          setStep(step + 1);
        }
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [step, activeAccordian]);

  const [tncIsOpen, setTncIsOpen] = useState(false);
  const [tncState, setTncState] = useState(true);

  useEffect(() => {
    if (tncIsOpen) {
      setTncState(true);
      setIsChecked(false);
    }
  }, [tncIsOpen]);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setTncState(false);
    } else {
      setTncState(true);
    }
  };

  const [submitTncState] = useState(false);
  const [tncAccepted, settncAccepted] = useState(false);

  const submitTnc = async (e) => {
    if (selectedProfile === "candidate" || selectedProfile === "evaluator") {
      settncAccepted(true);
      setCurrentStep(1);
      setTncIsOpen(false);

      switch (selectedProfile) {
        case "candidate":
          trackCandidateOnboarding(analyticsConstant.STEP1);
          break;
        case "evaluator":
          trackEvaluatorOnboarding(analyticsConstant.STEP1);
          break;
      }
    } else if (selectedProfile === "employer") {
      changeCompany(e);
    }
  };

  const [dataFromResume, setDataFromResume] = useState({
    first_name: "",
    last_name: "",
    gender: "",
    email: "",
    phone: "",
    totalExp: "",
    summary: "",
  });

  const [resumeLoading, setResumeLoading] = useState(false);

  useEffect(() => {
    const masterData = commonServices.getLocalData(storageConstants.DATA);
    if (
      masterData?.system_config?.resume_parser_enable !== undefined &&
      masterData?.system_config?.resume_parser_enable?.value !== undefined &&
      masterData?.system_config?.resume_parser_enable?.value == "1"
    ) {
      if (profile?.resume_file && tncAccepted) {
        autofillFromResume();
      }
    }
  }, [profile.resume_file, tncAccepted]);

  const autofillFromResume = () => {
    const formData = new FormData();
    formData.append("resume", profile?.resume_file);
    formData.append("fileName", profile?.resume_file_name);
    formData.append("type", "resume");
    setResumeLoading(true);

    ApiHelper.parseResume(formData).then((response) => {
      if (response.isSuccess === true) {
        setResumeLoading(false);

        const { first_name, last_name, email, phone_number, professional_summary, skill_chips, project_and_company_details, total_experience_months, location, education_degree, employment_history, alternate_phone_number, phone_number_country_code, alternate_phone_number_country_code, gender } = response.data;

        setDataFromResume({ first_name, last_name, email, gender, phone_number, alternate_phone_number, phone_number_country_code, alternate_phone_number_country_code, professional_summary, skill_chips, project_and_company_details, total_experience_months, location, education_degree, employment_history })
        setShowModalError(true);
        setModalErrorData({
          ...modalErrorData,
          heading: "TrueTalent Resume Reader",
          paragraph: 'Details parsed with AI may not be always accurate. Please check all fields before clicking on "Next"',
          buttonText: BUTTON_TEXT_OK,
        });
      } else {
        setResumeLoading(false);

        setShowModalError(true);
        setModalErrorData({
          ...modalErrorData,
          heading: "TrueTalent Resume Reader",
          paragraph:
            "Our AI is unable to parse your details. Kindly fill the details",
          buttonText: BUTTON_TEXT_OK,
        });
      }
    });
  };

  return (
    <>
      {loading ? (
        <div className="loader">
          <ClipLoader color={color} loading={true} css={override} size={150} />{" "}
        </div>
      ) : null}
      <ModalContactDetails
        showModal={showModalError}
        setShowModal={setShowModalError}
        modalData={modalErrorData}
      />

      <AppModal
        isOpen={tncIsOpen}
        closeModal={() => {
          setTncIsOpen(false);
          setTncState(true);
        }}
        size={"lg"}
        title={"Terms of Use & Privacy Policy"}
        scrollHidden={true}
      >
        <div style={{ maxHeight: "40vh", overflowY: "scroll" }}>
          <TermsContent />
        </div>
        <div className="mt-4">
          <Checkbox
            id="terms"
            onChange={handleCheckboxChange}
            checked={isChecked}
            label="I accept all the terms and conditions."
          />
        </div>

        <div className="mb-2 mt-3 d-flex gap-3 align-items-center justify-content-end">
          <Button
            buttonType="secondary"
            onClick={() => {
              setTncIsOpen(false);
              setTncState(true);
            }}
            text="Decline"
          />
          <Button
            buttonType="primary"
            isDisabled={tncState}
            onClick={(e) => submitTnc(e)}
            text={"Accept Terms and Conditions"}
            disabled={!isChecked}
          />
        </div>
      </AppModal>

      <AppModal
        title="TrueTalent Resume Reader"
        isOpen={showParserModal}
        closeModal={() => setShowParserModal(false)}
        scrollHidden={true}
        size={"lg"}
      >
        <Text
          type="h5"
          className="mt-4 text-center"
          text="Experience the magic of TrueTalent AI: effortlessly import from your resume"
        />

        <div className="mb-2 mt-3 d-flex gap-3 align-items-center justify-content-end">
          <Button
            buttonType="secondary"
            onClick={() => {
              setShowParserModal(false);
            }}
            text="Cancel"
          />
          <Button
            buttonType="primary"
            onClick={(e) => {
              document.getElementById("input-file-now").click();
              setShowParserModal(false);
            }}
            text={"Upload"}
          />
        </div>
      </AppModal>

      {(selectedProfile === "candidate" || selectedProfile === "evaluator") &&
        parseInt(currentStep) > 0 ? (
        <MaxWidthContainer className="tt-container">
          <RegisterV2
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            profile={profile}
            setProfile={setProfile}
            selectedProfile={selectedProfile}
            referralCode={referralCode}
            setReferralCode={setReferralCode}
            isHeaderUpdate={isHeaderUpdate}
            setIsHeaderUpdate={setIsHeaderUpdate}
            dataFromResume={dataFromResume}
            resumeLoading={resumeLoading}
          />
        </MaxWidthContainer>
      ) : (
        <MaxWidthContainer className="tt-container">
          <div className="main-body">
            <div className="register_page">
              <div className="row flex-column-reverse flex-md-row">
                <div className="col-12 col-md-6">
                  <Accordion
                    activeKey={step - 1}
                    onSelect={(index) => setStep(index + 1)}
                  >
                    {[...accordianData]?.map((item, index) => (
                      <Card
                        key={index}
                        onMouseEnter={() => setActiveAccordian(true)}
                        onMouseLeave={() => setActiveAccordian(false)}
                      >
                        <Card.Header
                          style={{
                            padding: "5px 0",
                            backgroundColor: "white",
                            borderBottom: "1px solid var(--gray5)",
                          }}
                        >
                          <AccordionButton variant="link" eventKey={index}>
                            <span
                              style={{
                                display: "flex",
                                gap: "10px",
                                flex: "1",
                                textAlign: "left",
                                alignItems: "center",
                                fontWeight: 500,
                              }}
                            >
                              <img
                                src={item?.icon}
                                style={{ objectFit: "contain" }}
                                height="30px"
                                width="30px"
                              />
                              {item?.title}
                            </span>
                          </AccordionButton>
                        </Card.Header>
                        <Accordion.Collapse eventKey={index}>
                          <Card.Body
                            style={{
                              padding: "30px",
                              background: "var(--gray2)",
                            }}
                          >
                            <img src={item?.image} height="150px" />
                            <Text
                              type="small"
                              fontSize={"sm"}
                              fontWeight={500}
                              mt={4}
                              text={item?.description}
                            />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    ))}
                  </Accordion>
                </div>
                <div className="col-12 col-md-6">
                  <div className="tt-register-form h-100 mb-4">
                    {registerSection === "form" ? (
                      selectedProfile === "employer" ? (
                        <div className=" ">
                          <div className="form-div header d-flex align-items-center justify-content-between">
                            <div>
                              <a className="hand-hover" onClick={() => back()}>
                                {" "}
                                <img
                                  className="back-arrow"
                                  src={arrowLeft}
                                ></img>
                              </a>
                            </div>
                            <div>
                              <h2 className="right_heading text-center title">
                                Create {APP_NAME} Account
                              </h2>
                            </div>
                            <div>
                              <p>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </p>
                            </div>
                          </div>

                          <div className="form-div">
                            <div className="col">
                              <div className="tab-content" id="nav-tabContent">
                                <div
                                  className={
                                    selectedProfile === "employer"
                                      ? "tab-pane fade show active"
                                      : "tab-pane fade"
                                  }
                                  id="list-profile"
                                  role="tabpanel"
                                  aria-labelledby="list-profile-list"
                                >
                                  {/*  create truetalent account form section start */}
                                  <form action="">
                                    <div className="row">
                                      <div className="col-12 col-md-6">
                                        <div className="form-group mb-3">
                                          <Input
                                            type="name"
                                            className="form-control"
                                            placeholder="First Name"
                                            id="con_Fname"
                                            label="First Name"
                                            isRequired={true}
                                            // value={fname}
                                            autoComplete="off"
                                            name="com_Fname"
                                            onChange={(e) => {
                                              setFname(e.target.value);
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <div className="form-group mb-3">
                                          <Input
                                            type="name"
                                            className="form-control"
                                            placeholder="Last Name"
                                            id="com_Lname"
                                            label="Last Name"
                                            isRequired={true}
                                            // value={last_name}
                                            autoComplete="off"
                                            name="com_Lname"
                                            onChange={(e) => {
                                              setLast_name(e.target.value);
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 ">
                                        <div className="form-group mb-3">
                                          <Input
                                            type="email"
                                            className="form-control"
                                            placeholder="Business Email Address"
                                            id="com_email"
                                            label="Business Email Address"
                                            isRequired={true}
                                            // value={email}
                                            autoComplete="off"
                                            name="com_Lname"
                                            onChange={(e) => {
                                              setEmail(e.target.value);
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12">
                                        <div className="form-group mb-3">
                                          <Input
                                            type="phone"
                                            name="contact_number"
                                            placeholder="Phone Number"
                                            isRequired={true}
                                            defaultCountry={COUNTRY}
                                            value={
                                              phoneNumber && phoneNumber !== ""
                                                ? normalizePhoneNumber1(
                                                  phoneNumber
                                                )
                                                : ""
                                            }
                                            onChange={(number) => {
                                              setPhoneNumber(number);
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <div className="form-group mb-3">
                                          <Input
                                            type={
                                              passwordShown
                                                ? "text"
                                                : "password"
                                            }
                                            placeholder="Password"
                                            isRequired={true}
                                            id="com_pass"
                                            label="Password"
                                            onChange={(e) => {
                                              setPassword(e.target.value);
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <div className="form-group mb-3">
                                          <Input
                                            type={
                                              passwordShown
                                                ? "text"
                                                : "password"
                                            }
                                            placeholder="Confirm Password"
                                            id="com_conpass"
                                            label="Confirm Password"
                                            isRequired={true}
                                            onChange={(e) => {
                                              setConfirm_password(
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12">
                                        <div className="form-group">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="code"
                                            label="Referral Code"
                                            placeholder="Have a Referral Code?"
                                            onChange={(e) => {
                                              setReferralCode(e.target.value);
                                            }}
                                            value={referralCode}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <h2
                                      className=" mb-3"
                                      style={{ marginTop: "30px" }}
                                    >
                                      Company Details
                                    </h2>
                                    <div className="row">
                                      <div className="col-12">
                                        <div className="form-group mb-3">
                                          <Input
                                            type="text"
                                            className="form-control"

                                            label="Company Name"
                                            isRequired={true}
                                            placeholder="Company Name"
                                            onChange={(e) => {
                                              setCompany_name(e.target.value);
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <div className="form-group mb-3">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="website"
                                            placeholder="Website"
                                            isRequired={true}
                                            label="Website"
                                            onChange={(e) => {
                                              setWebsite(e.target.value);
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <div className="form-group mb-3">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="search_location"
                                            autoComplete="off"
                                            placeholder="Location"
                                            label="Location"
                                            isRequired={true}
                                            value={location}
                                            onKeyDown={(event) => {
                                              if (
                                                event.which === 27 ||
                                                event.keyCode === 27
                                              ) {
                                                setLocSuggestion([]);
                                              }
                                            }}
                                            onChange={(e) => {
                                              onChangeHandler_location(
                                                e.target.value
                                              );
                                              setLocation(e.target.value);
                                            }}
                                          />
                                          <div className={locSuggestion?.length > 0
                                            ? " suggestion_box active"
                                            : "suggestion_box"}>
                                            {locSuggestion &&
                                              locSuggestion.map(
                                                (suggestion, i) => (
                                                  <p
                                                    className=" sugg_p hand-hover"
                                                    key={i}
                                                    onClick={() => {
                                                      setLocation(
                                                        `${suggestion.name}, ${suggestion.description}`
                                                      );
                                                      setLocationId(
                                                        suggestion.id
                                                      );
                                                      document.getElementById(
                                                        "search_location"
                                                      ).value = "";
                                                      onChangeHandler_location(
                                                        ""
                                                      );
                                                    }}
                                                  >
                                                    {suggestion.name},{" "}
                                                    {suggestion.description}
                                                  </p>
                                                )
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      {/* create truetalent account form section ends */}

                                      <div className="col-md-6">
                                        <Dropdown
                                          label="Company Size"
                                          id="company-size"
                                          name="company-size"
                                          isRequired={true}
                                          showDefaultOption={true}
                                          select={masterData?.company_sizes}
                                          onChange={() =>
                                            setCompany_size(
                                              document.getElementById(
                                                "company-size"
                                              ).value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <Dropdown
                                          label="Industry Domain"
                                          id="industry-domain"
                                          name="industry-domain"
                                          isRequired={true}
                                          showDefaultOption={true}
                                          select={sortArrayByName(
                                            masterData?.industry_domains
                                          )}
                                          onChange={() =>
                                            setIndustry_domain(
                                              document.getElementById(
                                                "industry-domain"
                                              ).value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="row mt-3">
                                      <div className="col-12">
                                        <Button
                                          buttonType="primary"
                                          text="Create Account"
                                          type="submit"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (!Boolean(validate())) {
                                              setTncIsOpen(true);
                                            }
                                          }}
                                          style={{
                                            padding: "12px",
                                            width: "100%",
                                          }}
                                        />
                                      </div>
                                      <div className="col-12">
                                        <p className="login_txt text-center mobile-text mb-3">
                                          Already have an account?{" "}
                                          <a
                                            href=""
                                            className="body-links"
                                            onClick={changeRoute}
                                          >
                                            Login
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div></div>
                        </div>
                      ) : (selectedProfile === "candidate" ||
                        selectedProfile === "evaluator") &&
                        currentStep === 0 ? (
                        <ResumeUpload
                          setCurrentStep={setCurrentStep}
                          profile={profile}
                          setProfile={setProfile}
                          selectedProfile={selectedProfile}
                          setSelectedProfile={setSelectedProfile}
                          getGoogleLogin={getGoogleLogin}
                          setRegisterSection={setRegisterSection}
                          setTncIsOpen={setTncIsOpen}
                        />
                      ) : null
                    ) : (
                      <EmailVerification
                        email={email}
                        resendVerificationEmail={resendVerificationEmail}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MaxWidthContainer>
      )}
    </>
  );
}
