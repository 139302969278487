import { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import "./Jobs.scss";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import * as toast from "@/wrapper/toast";
import * as ApiHelper from "@/services/apiHelper";
import * as storageConstants from "@/constants/storageConstants";
import { CURRENCY } from "@/constants/storageConstants";
import MultiRangeSlider from "../Common/MultiRangeSlider/TotalExpSlider";
import * as commonServices from "@/services/common";
import emptyResult from "@/Assets/svgs/search_no_result.svg";
import { isLoggedIn } from "@/utils/authUtil";
import PayWall from "@Components/Common/PayWall";
import DateRangePicker from "@Components/Common/DateRangePicker";
import CustomPagination from "@Components/Pagination/Pagination";
import {
  addMultipleSearchParamsInHistory,
  addSearchParamsInHistory,
  removeSearchParamsInHistory,
  removeMultipleSearchParamsInHistory,
  removeSearchParamsInHistoryWithoutValue,
  removeAllParams,
  redirectBack,
} from "@/utils/redirectUtil";
import mapPin from "@/Assets/svgs/map-pin.svg";
import {
  getBarColor,
  getPercentage,
} from "@/services/common";
import {
  createSlugFromString,
  getReferralJobTooltip,
} from "@/utils/stringUtils";
import ModalLocation from "@Components/Modals/ModalLocation";
import ModalIndustryDomain from "@Components/Modals/ModalIndustryDomain";
import { changeDateFormat } from "@/utils/dateUtil";
import { getCandidateExperienceText } from "@/services/common";
import { addNavShadow } from "@/utils/styleUtils";
import {
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";
import referredJobImg from "@/Assets/svgs/referredJob.svg";
import {
  INVALID_KEYWORD_ERROR_MESSAGE,
} from "@/constants/stringConstants";
import { validateKeyword } from "@/utils/validation";
import { MaxWidthContainer } from "@Components/Common/MaxWidthContainer";
import { RxCross2 } from "react-icons/rx";
import { highlightText, isNullOrEmpty } from "../../utils/stringUtils";
import Button from "../_widgets/Button/Button";
import FilterBar from "../_widgets/FIlterBar/FilterBar";
import Text from "../_widgets/Text/Text";
import { JOB_SEARCH_FILTERS, ORDER_BY, ORDER_BY_TEXT, SEARCH_FILTERS } from "../../constants/searchConstants";
import { convertArrayElementsToInteger, transformArrayFromObject } from "../../utils/arrayUtils";
import { getChipsNameFromMasterData, getLocationChipsName, getSkillChipsName, returnNewPayloadArray } from "../../utils/searchUtils";
import IconResource from "../_widgets/IconResource/IconResource";
import CustomTooltip from '../../../src/utils/CustomTooltip'
import { isCandidate } from "../../utils/authUtil";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function Jobs({
  jobSearchName,
  searchType,
  keywordSearch,
  setShowStickySearchBar,
  isStickySearch,
  setIsStickySearch,
  setSearchFilterState,
  keywordSeachFilters,
  setKeywordSeachFilters,
}) {
  let paramss = useParams();
  let paramType = paramss.type;
  const params = new URLSearchParams(window.location.search);

  const [filter, setFilter] = useState(false);
  const [filting, setFilting] = useState("hidden");
  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");
  const [showModalLocation, setShowModalLocation] = useState(false);
  const datePickerStartRef = useRef();
  const datePickerEndRef = useRef();

  const [isShowPercentage, setIsShowPercentage] = useState(false);

  const masterData = commonServices.getLocalData(storageConstants.DATA) || {};

  const jobLocations = commonServices.getLocalData(storageConstants.LOCATIONS);

  var searchTypeParam = params.getAll(SEARCH_FILTERS?.SEARCH_TYPE);
  var keywordParam = params.getAll(SEARCH_FILTERS?.KEYWORD);

  const [keyword, setKeyword] = useState(keywordParam && keywordParam[0] !== undefined ? keywordParam[0] : keywordSearch ? keywordSearch : "");
  const [searchBy, setSearchBy] = useState(searchTypeParam && keywordParam[0] !== undefined ? searchTypeParam[0] : searchType ? searchType : "smart");

  const [orderByUser, setOrderByUser] = useState(false);

  const setIsKeywordReset = (value) => {
    setSearchPayload({ ...searchPayload, keywordReset: [value] })
  }

  const initialDisplayLocations = {
    count: commonServices.getLocalData(storageConstants.LOCATIONS)?.length, arr: commonServices.getLocalData(storageConstants.LOCATIONS) || []
  }
  const [displayLocations, setDisplayLocations] = useState(initialDisplayLocations);

  const initialDisplayDomains = {
    count: masterData?.industry_domains?.length, arr: masterData?.industry_domains || []
  }
  const [displayDomains, setDisplayDomains] = useState(initialDisplayDomains);

  const [activePage, setActivePage] = useState(1);

  const setPreferredSkillData = (arr) => {
    arr && commonServices.storeLocalData(storageConstants.SKILLS, arr);
  }

  const handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    FindWork(activePage);
    setActivePage(activePage);
  };

  const [resetSlider, setResetSlider] = useState(true);

  useEffect(() => {
    window.addEventListener('popstate', handleBrowserNavigation);
    setParamsFromURL();

    return () => {
      window.removeEventListener('popstate', handleBrowserNavigation);
    };
  }, []);

  const handleBrowserNavigation = (event) => {
    // Back or forward button clicked
    if (event.state) {
      setParamsFromURL();
    }
  };

  const defaultPayload = {
    skills: [], locations: [], min_salary: [], max_salary: [], salary_id: [], job_types: [], preferences: [], durations: [], salary_types: [], domains: [], min_exp: [0], max_exp: [20], travel_percent: ['0'], eeo: ['0'], start_date: [null], end_date: [null], orderBy: [isLoggedIn() && isCandidate() ? ORDER_BY.RELEVANT : ORDER_BY.NEWEST], orderByText: [isLoggedIn() && isCandidate() ? ORDER_BY_TEXT.RELEVANT : ORDER_BY_TEXT.NEWEST], keywordReset: [false],
  };



  var skillParam = params.getAll(JOB_SEARCH_FILTERS?.SKILLS);
  var domainParam = params.getAll(JOB_SEARCH_FILTERS?.DOMAINS);
  // This payload will be used for first/raw API call
  const initialPayload = {
    skills: convertArrayElementsToInteger(skillParam), locations: [], min_salary: [], max_salary: [], salary_id: [], job_types: [], preferences: [], durations: [], salary_types: [], domains: convertArrayElementsToInteger(domainParam), min_exp: [0], max_exp: [20], travel_percent: ['0'], eeo: ['0'], start_date: [null], end_date: [null], orderBy: [isLoggedIn() && isCandidate() ? ORDER_BY.RELEVANT : ORDER_BY.NEWEST], orderByText: [isLoggedIn() && isCandidate() ? ORDER_BY_TEXT.RELEVANT : ORDER_BY_TEXT.NEWEST], keywordReset: [false],
  };

  const [searchPayload, setSearchPayload] = useState(initialPayload);

  const setParamsFromURL = () => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);

    const keyArr = [];
    const valArr = [];
    for (const [key, value] of searchParams.entries()) {
      keyArr.push(key);
      valArr.push(value);
    }

    setDataInPayload(keyArr, valArr);
  }

  useEffect(() => {
    setResetSlider((prev) => !prev);
  }, [searchPayload.min_exp, searchPayload.max_exp]);

  const singleValueArrTypes = [JOB_SEARCH_FILTERS.MIN_EXP, JOB_SEARCH_FILTERS.MAX_EXP, JOB_SEARCH_FILTERS.TRAVEL, JOB_SEARCH_FILTERS.START_DATE, JOB_SEARCH_FILTERS.END_DATE];

  const multiValueArrTypes = [JOB_SEARCH_FILTERS.SKILLS, JOB_SEARCH_FILTERS.LOCATIONS, JOB_SEARCH_FILTERS.SALARY_ID, JOB_SEARCH_FILTERS.MIN_SALARY, JOB_SEARCH_FILTERS.MAX_SALARY, JOB_SEARCH_FILTERS.JOB_TYPES, JOB_SEARCH_FILTERS.PREFERENCES, JOB_SEARCH_FILTERS.DURATIONS, JOB_SEARCH_FILTERS.SALARY_TYPES, JOB_SEARCH_FILTERS.DOMAINS];

  const specialTypes = [JOB_SEARCH_FILTERS.EEO];

  const validKeys = [JOB_SEARCH_FILTERS.SKILLS, JOB_SEARCH_FILTERS.LOCATIONS, JOB_SEARCH_FILTERS.SALARY_ID, JOB_SEARCH_FILTERS.MIN_SALARY, JOB_SEARCH_FILTERS.MAX_SALARY, JOB_SEARCH_FILTERS.JOB_TYPES, JOB_SEARCH_FILTERS.PREFERENCES, JOB_SEARCH_FILTERS.DURATIONS, JOB_SEARCH_FILTERS.SALARY_TYPES, JOB_SEARCH_FILTERS.DOMAINS, JOB_SEARCH_FILTERS.MIN_EXP, JOB_SEARCH_FILTERS.MAX_EXP, JOB_SEARCH_FILTERS.TRAVEL, JOB_SEARCH_FILTERS.START_DATE, JOB_SEARCH_FILTERS.END_DATE, JOB_SEARCH_FILTERS.EEO];

  const setDataInPayload = (keyArr, valueArr) => {
    let tempPayload = {
      skills: [], locations: [], min_salary: [], max_salary: [], salary_id: [], job_types: [], preferences: [], durations: [], salary_types: [], domains: [], min_exp: [0], max_exp: [20], travel_percent: ['0'], eeo: ['0'], start_date: [null], end_date: [null], orderBy: [isLoggedIn() && isCandidate() ? ORDER_BY.RELEVANT : ORDER_BY.NEWEST], orderByText: [isLoggedIn() && isCandidate() ? ORDER_BY_TEXT.RELEVANT : ORDER_BY_TEXT.NEWEST], keywordReset: [false],
    };

    if (Array.isArray(keyArr) && Array.isArray(valueArr) &&
      keyArr.length > 0 && valueArr.length === keyArr.length) {
      keyArr.forEach((key, index) => {
        if (validKeys.includes(key)) {
          if (!tempPayload[key]) {
            tempPayload[key] = [];
          }

          if (singleValueArrTypes.includes(key) || specialTypes.includes(key)) {
            if (key === 'start_date' || key === 'end_date') {
              tempPayload[key][0] = new Date(valueArr[index]);
            } else if (key === 'min_exp' || key === 'max_exp') {
              tempPayload[key][0] = parseInt(valueArr[index]);
            } else {
              tempPayload[key][0] = valueArr[index];
            }
          } else if (multiValueArrTypes.includes(key)) {
            if (!tempPayload[key].includes(parseInt(valueArr[index]))) {
              tempPayload[key].push(parseInt(valueArr[index]));
            }
          }
        }
      });
    }

    if (isLoggedIn() && isCandidate() && !orderByUser) {
      tempPayload['orderBy'] = [ORDER_BY.RELEVANT];
      tempPayload['orderByText'] = [ORDER_BY_TEXT.RELEVANT];
    }
    else if (!isLoggedIn() && !isCandidate() && !orderByUser) {
      tempPayload['orderBy'] = [ORDER_BY.NEWEST];
      tempPayload['orderByText'] = [ORDER_BY_TEXT.NEWEST];
    }

    setSearchPayload(tempPayload);
  };

  const handleDisplayLocationChange = (text) => {
    const locArr = commonServices.getLocalData(storageConstants.LOCATIONS);

    if (locArr) {
      if (text.length > 1) {
        const arr = locArr.length > 0 ? locArr.filter(l => l.name.toLowerCase().includes(text.toLowerCase())) : [];
        setDisplayLocations({ count: arr.length, arr });
      } else {
        setDisplayLocations({ count: locArr.length, arr: locArr });
      }
    }
  }

  const handleDisplayDomainsChange = (text) => {
    const domainsArr = masterData?.industry_domains || [];

    if (domainsArr) {
      if (text.length > 1) {
        const arr = domainsArr.length > 0 ? domainsArr.filter(d => d.name.toLowerCase().includes(text.toLowerCase())) : [];
        setDisplayDomains({ count: arr.length, arr });
      } else {
        setDisplayDomains({ count: domainsArr.length, arr: domainsArr });
      }
    }
  }

  const handleDateFormatChange = (fromDate, toDate) => {
    return `${changeDateFormat(fromDate)} - ${changeDateFormat(toDate)}`
  }

  const handleTotalExpChange = (minYears, maxYears) => {
    let val;
    if (
      (minYears === 0 && maxYears !== 20) ||
      (minYears !== 0 && maxYears === 20) ||
      (minYears !== 0 && maxYears !== 20)
    ) {
      val = minYears + " Years - " + maxYears + " Years";
    } else {
      val = null;
    }
    return val;
  };

  const manageDataInPayload = (type, data, action) => {
    const { SKILLS, LOCATIONS, MIN_SALARY, MAX_SALARY, SALARY_ID, JOB_TYPES, PREFERENCES, DOMAINS, DURATIONS, SALARY_TYPES, TRAVEL, MIN_EXP, MAX_EXP, EEO, START_DATE, END_DATE, } = JOB_SEARCH_FILTERS;
    let tempPayload = { ...searchPayload };

    if (action === 'a') {
      if (type === SKILLS || type === LOCATIONS) {
        const newData = typeof data === 'function' ? data(tempPayload[type]) : data;

        if (Array.isArray(newData)) {
          tempPayload[type] = newData;
        }
        else {
          tempPayload[type] = returnNewPayloadArray(tempPayload[type], newData, action);
          addSearchParamsInHistory(type, newData);
        }

        if (isLoggedIn() && isCandidate() && !orderByUser) {
          tempPayload['orderBy'] = [ORDER_BY.RELEVANT];
          tempPayload['orderByText'] = [ORDER_BY_TEXT.RELEVANT];
        }
        else if (!isLoggedIn() && !orderByUser) {
          tempPayload['orderBy'] = [ORDER_BY.NEWEST];
          tempPayload['orderByText'] = [ORDER_BY_TEXT.NEWEST];
        }
      }
      else if (type === PREFERENCES || type === JOB_TYPES || type === DOMAINS || type === DURATIONS || type === SALARY_TYPES) {
        tempPayload[type] = returnNewPayloadArray(tempPayload[type], data, action);
        addSearchParamsInHistory(type, data);
      }
      else if (type === SALARY_ID) {
        let newData = (masterData?.salary_range || [])?.filter(sr => sr.id === data);

        const idArr = returnNewPayloadArray(tempPayload[type], data, action);
        tempPayload[type] = idArr;

        const min = parseInt(commonServices.removeCommaFromAmount(newData[0]?.value?.split("-")[0].trim()));
        const max = parseInt(commonServices.removeCommaFromAmount(newData[0]?.value?.split("-")[1].trim()));
        const minArr = returnNewPayloadArray(tempPayload[MIN_SALARY], min, action);
        const maxArr = returnNewPayloadArray(tempPayload[MAX_SALARY], max, action);
        tempPayload[MIN_SALARY] = minArr;
        tempPayload[MAX_SALARY] = maxArr;

        addMultipleSearchParamsInHistory([MIN_SALARY, MAX_SALARY, SALARY_ID], [min, max, data]);
      }
      else if (type === START_DATE || type === END_DATE) {
        tempPayload[type] = [data];
      }
    }
    else if (action === 'r') {
      if (type === SKILLS || type === LOCATIONS) {
        removeSearchParamsInHistory(type, data);
        const arr = returnNewPayloadArray(tempPayload[type], data, action);
        tempPayload[type] = arr;

        if (isLoggedIn() && isCandidate() && !orderByUser) {
          tempPayload['orderBy'] = [ORDER_BY.RELEVANT];
          tempPayload['orderByText'] = [ORDER_BY_TEXT.RELEVANT];
        }
        else if (!isLoggedIn() && !orderByUser) {
          tempPayload['orderBy'] = [ORDER_BY.NEWEST];
          tempPayload['orderByText'] = [ORDER_BY_TEXT.NEWEST];
        }
      }
      else if (type === PREFERENCES || type === JOB_TYPES || type === DOMAINS || type === DURATIONS || type === SALARY_TYPES) {
        tempPayload[type] = returnNewPayloadArray(tempPayload[type], data, action);
        removeSearchParamsInHistory(type, data);
      }
      else if (type === SALARY_ID) {
        const newData = (masterData?.salary_range || [])?.filter(s => s.id === data);

        const idArr = returnNewPayloadArray(tempPayload[type], data, action);
        tempPayload[type] = idArr;

        const min = parseInt(commonServices.removeCommaFromAmount(newData[0]?.value?.split("-")[0].trim()));
        const max = parseInt(commonServices.removeCommaFromAmount(newData[0]?.value?.split("-")[1].trim()));
        const minArr = returnNewPayloadArray(tempPayload[MIN_SALARY], min, action);
        const maxArr = returnNewPayloadArray(tempPayload[MAX_SALARY], max, action);
        tempPayload[MIN_SALARY] = minArr;
        tempPayload[MAX_SALARY] = maxArr;

        removeMultipleSearchParamsInHistory([MIN_SALARY, MAX_SALARY, SALARY_ID], [min, max, data]);
      }
      else if (type === START_DATE || type === END_DATE) {
        tempPayload[START_DATE] = [null];
        tempPayload[END_DATE] = [null];
        removeSearchParamsInHistoryWithoutValue([START_DATE, END_DATE]);
      }
      else if (type === MIN_EXP || type === MAX_EXP) {
        tempPayload[MIN_EXP] = [0];
        tempPayload[MAX_EXP] = [20];
        setResetSlider((prev) => !prev);
        removeSearchParamsInHistoryWithoutValue([MIN_EXP, MAX_EXP]);
      }
      else if (type === EEO) {
        tempPayload[type] = ['0'];
        removeSearchParamsInHistory(type, '1');
      }
      else if (type === TRAVEL) {
        tempPayload[type] = ['0'];
        removeSearchParamsInHistoryWithoutValue([type, 'travel']);
      }
    }
    else {
      if (type === MIN_EXP || type === MAX_EXP) {
        tempPayload[type] = [data];
      }
      else if (type === EEO) {
        tempPayload[type] = [data];
        data === '1'
          ? addSearchParamsInHistory(type, '1')
          : removeSearchParamsInHistory(type, '1');
      }
      else if (type === TRAVEL) {
        tempPayload[type] = [data];
        data !== '0'
          ? addMultipleSearchParamsInHistory([type, 'travel'], [data, '1'])
          : removeSearchParamsInHistoryWithoutValue(type, 'travel');
      }
      else if (type === 'dates') {
        tempPayload[START_DATE] = [data[0]];
        tempPayload[END_DATE] = [data[1]];
      }
    }

    setSearchPayload(tempPayload);
  }

  const handleRemoveChips = (data) => {
    const k = Object.keys(data)[0];
    const v = Object.values(data)[0];
    const action = 'r'
    // const action = k === 'min_exp' || k === 'max_exp' ? '' : 'r'

    return manageDataInPayload(k, v, action);
  }

  const getChipsName = (data) => {
    const key = Object.keys(data);
    const value = Object.values(data);

    const { SKILLS, LOCATIONS, SALARY_ID, JOB_TYPES, PREFERENCES, DOMAINS, DURATIONS, SALARY_TYPES, TRAVEL, MIN_EXP, EEO, START_DATE } = JOB_SEARCH_FILTERS;

    if (key[0] === LOCATIONS && searchPayload.locations.includes(value[0])) {
      return getLocationChipsName(value[0]);
    }
    else if (key[0] === SKILLS && searchPayload.skills.includes(value[0])) {
      return getSkillChipsName(value[0]);
    }
    else if (key[0] === JOB_TYPES && searchPayload.job_types.includes(value[0])) {
      return getChipsNameFromMasterData(JOB_TYPES, value[0]);
    }
    else if (key[0] === PREFERENCES && searchPayload.preferences.includes(value[0])) {
      return getChipsNameFromMasterData('joining_preferences', value[0]);
    }
    else if (key[0] === DURATIONS && searchPayload.durations.includes(value[0])) {
      return getChipsNameFromMasterData('job_durations', value[0]);
    }
    else if (key[0] === DOMAINS && searchPayload.domains.includes(value[0])) {
      return getChipsNameFromMasterData('industry_domains', value[0]);
    }
    else if (key[0] === SALARY_TYPES && searchPayload.salary_types.includes(value[0])) {
      return getChipsNameFromMasterData(SALARY_TYPES, value[0]);
    }
    else if (key[0] === MIN_EXP && value[0] !== null && searchPayload.min_exp.includes(value[0])) {
      return handleTotalExpChange(searchPayload.min_exp[0], searchPayload.max_exp[0]);
    }
    else if (key[0] === START_DATE && value[0] !== null && searchPayload.start_date[0] !== null && searchPayload.end_date[0] !== null) {
      return handleDateFormatChange(searchPayload.start_date[0], searchPayload.end_date[0]);
    }
    else if (key[0] === SALARY_ID && searchPayload.salary_id.includes(value[0])) {
      return getChipsNameFromMasterData('salary_range', value[0]);
    }
    else if (key[0] === TRAVEL && searchPayload.travel_percent.includes(value[0]) && searchPayload.travel_percent[0] !== '0') {
      return `Travel Required: ${searchPayload.travel_percent[0]}%`
    }
    else if (key[0] === EEO && searchPayload.eeo.includes(value[0]) && searchPayload.eeo[0] === '1') {
      return 'Equal Opportunity Employer';
    }
    else {
      return '';
    }
  };

  const clearFilters = (e) => {
    e.preventDefault();
    setOrderByUser(false);
    setKeyword("");
    removeAllParams();
    setResetSlider((prev) => !prev);
    document.getElementById("search_location_filter").value = "";
    document.getElementById("search_industry_domains_filter").value = "";
    setDisplayLocations(initialDisplayLocations);
    setDisplayDomains(initialDisplayDomains);
    setSearchPayload(defaultPayload);
  };

  const [isDateFilter, setIsDateFilter] = useState(false);

  const [featuredData, setFeaturedData] = useState([]);
  const [referralJobs, setReferralJobs] = useState([]);
  const [noResultErr, setNoResultErr] = useState(false);
  const [isPaywallActive, setIsPaywallActive] = useState(false);
  const [isPaywallActiveCancel, setIsPaywallActiveCancel] = useState(false);

  const onLocationChange = (e, element) => {
    if (e.target.checked) {
      manageDataInPayload('locations', element.id, 'a');
      document.getElementById("search_location_filter").value = "";
      addSearchParamsInHistory('locations', element.id);
    } else {
      manageDataInPayload('locations', element.id, 'r');
    }
  };

  const isValidRequest = () => {
    return (
      searchType === "smart" ||
      (searchType === "keyword" && validateKeyword(keyword))
    );
  };

  const isKeywordSearch = () => {
    return keyword.trim();

  }

  const FindWork = async (activePage) => {
    if (isValidRequest()) {
      if (isKeywordSearch()) {
        if (searchPayload?.orderBy[0] === ORDER_BY.RELEVANT) {
          setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.NEWEST], orderByText: [ORDER_BY_TEXT.NEWEST] });
          return;
        }
      } else {
        if (isLoggedIn() && isCandidate() && !orderByUser && searchPayload?.orderBy[0] !== ORDER_BY.RELEVANT) {
          setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.RELEVANT], orderByText: [ORDER_BY_TEXT.RELEVANT] });
          return;
        }
      }
      window.scrollTo(0, 0);
      setLoading(true);

      const { skills, locations, job_types, preferences, start_date, end_date, travel_percent, eeo, min_salary, max_salary, min_exp, max_exp, domains, durations, salary_types, orderBy } = searchPayload;

      const obj = {
        skills,
        locations,
        job_types,
        joining_preferences: preferences,
        job_from: changeDateFormat(start_date[0], 'YYYY-MM-DD'),
        job_to: changeDateFormat(end_date[0], 'YYYY-MM-DD'),
        travel_required: travel_percent[0] === '0' ? '0' : '1',
        work_auths: [],
        eeo: eeo[0],
        travel_percentage: travel_percent[0],
        min_salary,
        max_salary,
        min_experience: 12 * min_exp,
        max_experience: 12 * max_exp,
        industry_domains: domains,
        job_durations: durations,
        salary_types,
        limit: 72,
        order_by: orderBy[0],
        pageNumber: activePage,
        q: keyword,
        keywordSeachFilters: keywordSeachFilters,
      };

      await ApiHelper.searchJob(obj).then((response) => {
        setLoading(false);
        if (response.isSuccess) {
          window.scrollTo(0, 0);
          setFeaturedData(response.data.jobs);
          setReferralJobs(response.data.boost_jobs);
          setActivePage(response.data.jobs.current_page);
          setIsShowPercentage(response.data.show_percentage);
          if (response.data.jobs.data.length < 1) {
            setNoResultErr(true);
          }
        } else {
          toast.warn(response.message);
        }
      });
    } else {
      toast.warn(INVALID_KEYWORD_ERROR_MESSAGE);
    }
  };

  const [workRun, setWorkRun] = useState(0);

  useEffect(() => {
    if (workRun === false) {
      setWorkRun(true);
    } else {
      FindWork(activePage);

      let skillName = '';
      let locationName = '';
      if (searchPayload.skills.length > 0) {
        skillName = getSkillChipsName(searchPayload.skills[0]);
      }
      if (searchPayload.locations.length > 0) {
        locationName = getLocationChipsName(searchPayload.locations[0]);
      }

      setSearchFilterState({ skill: [skillName], location: [locationName], keyword, searchType });
    }
  }, [searchPayload]);

  useEffect(() => {
    ApiHelper.getLocations().then((response) => {
      if (response.isSuccess) {
        commonServices.storeLocalData(storageConstants.LOCATIONS, response.data);
        setDisplayLocations({ count: response.data.length - 10, arr: response.data });
      } else {
        console.log("error");
      }
    });

    ApiHelper.getSkills().then((response) => {
      if (response.isSuccess) {
        var resdata = [];
        response.data.map((data, i) => {
          resdata.push(data);
        });

      } else {
        console.log("error");
      }
    });
  }, []);

  const [showDomainsModal, setShowDomainsModal] = useState(false);

  const [isSticky, setIsSticky] = useState(false);
  const [y, setY] = useState(document.scrollingElement.scrollHeight);

  const handleNavigation = useCallback(
    (e) => {
      if (y > window.scrollY) {
        filterScrollTopNav();
      } else if (y < window.scrollY) {
        filterScrollDownNav();
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const filterScrollDownNav = () => {
    const searchElement = document.getElementById("result_section");
    if (
      searchElement !== null &&
      searchElement.getBoundingClientRect().top < 0
    ) {
      setShowStickySearchBar(true);
      addNavShadow();
      setIsStickySearch(false);
    }
  };

  const filterScrollTopNav = () => {
    const searchElement = document.getElementById("result_section");
    if (
      searchElement !== null &&
      searchElement.getBoundingClientRect().top > 0
    ) {
      addNavShadow();
      setShowStickySearchBar(false);
      setIsStickySearch(false);
    }
  };

  const navigateJobDetail = (jobData) => {
    window.open(
      "/job/details/" +
      jobData.eid +
      "/" +
      createSlugFromString(jobData.title) +
      "?q=" +
      keyword,
      "_blank"
    );
  };

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  return (
    <>
      {loading ? (
        <div className="loader">
          <ClipLoader color={color} loading={true} css={override} size={150} />
        </div>
      ) : null}

      {isPaywallActive ? (
        <PayWall
          onCancel={() => {
            redirectBack();
          }}
        />
      ) : null}

      <div
        className={`overlay ${filting}`}
        onClick={() => {
          setFilter(false);
          setFilting("hidden");
        }}
      ></div>

      <ModalLocation
        showModal={showModalLocation}
        setShowModal={setShowModalLocation}
        locations={jobLocations}
        selectedLocations={searchPayload.locations}
        onChange={onLocationChange}
      />

      <ModalIndustryDomain
        showModal={showDomainsModal}
        setShowModal={setShowDomainsModal}
        industryDomains={masterData?.industry_domains || []}
        checked={searchPayload.domains}
        manageDomains={(id, action) => manageDataInPayload("domains", id, action)}
      />

      <MaxWidthContainer
        pageKey={
          paramType !== undefined && paramType !== null
            ? "job-" + paramType
            : "job_search"
        }
      >
        <div className="job_page">
          <div className="body_section tt-container">
            <FilterBar
              searchedSkills={searchPayload.skills}
              setSearchedSkills={(type, data) => manageDataInPayload(type, data, 'a')}
              searchedLocations={searchPayload.locations}
              setSearchedLocations={(type, data) => manageDataInPayload(type, data, 'a')}
              FindWork={FindWork}
              searchType={searchBy}
              setSearchType={setSearchBy}
              keyword={keyword}
              setKeyword={setKeyword}
              searchBar={"normal"}
              module="Jobs"
              keywordSeachFilters={keywordSeachFilters}
              setKeywordSeachFilters={setKeywordSeachFilters}
              setPreferredSkillData={setPreferredSkillData}
              isStickySearch={isStickySearch}
              activePage={activePage}
              isKeywordReset={searchPayload.keywordReset[0]}
              setIsKeywordReset={setIsKeywordReset}
            />

            <div className="result_section" id="result_section">
              {featuredData?.data?.length > 0 ? (
                <div
                  className={
                    isSticky ? "result_feed sticky-effect" : "result_feed"
                  }
                  id="result_feed"
                >
                  <div className="result_header">
                    <h2 className="topTitle">Search result</h2>
                    <div className="result_header_sort">
                      <p>Sort by:</p>
                      <div className="dropdown">
                        <a
                          className="btn btn-secondary dropdown-toggle"
                          href={undefined}
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {searchPayload.orderByText}
                        </a>

                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.NEWEST], orderByText: [ORDER_BY_TEXT.NEWEST] });
                                setOrderByUser(true);
                              }}
                              href={undefined}
                            >
                              Newest First
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.OLDEST], orderByText: [ORDER_BY_TEXT.OLDEST] });
                                setOrderByUser(true);
                              }}
                              href={undefined}
                            >
                              Oldest First
                            </a>
                          </li>
                          {isShowPercentage ? (
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.RELEVANT], orderByText: [ORDER_BY_TEXT.RELEVANT] });
                                  setOrderByUser(true);
                                }}
                                href={undefined}
                              >
                                Most Relevant
                              </a>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                      <div className="filter_tab_header">
                        <div
                          className="list-group"
                          id="list-tab-1"
                          role="tablist"
                        >
                          <a
                            className="list-group-item list-group-item-action active"
                            id="list_1"
                            data-bs-toggle="list"
                            href="#list_1_content"
                            role="tab"
                            aria-controls="list_1_content"
                          >
                            <div className="round">
                              <img
                                onError={commonServices.imgError}
                                src="/Assets/svgs/list_view_icon.svg"
                                alt=""
                                className="ig"
                              />
                            </div>
                          </a>
                          <a
                            className="list-group-item list-group-item-action"
                            id="list_2"
                            data-bs-toggle="list"
                            href="#list_2_content"
                            role="tab"
                            aria-controls="list_2_content"
                          >
                            <div className="round">
                              <img
                                onError={commonServices.imgError}
                                src="/Assets/svgs/grid_view_icon.svg"
                                alt=""
                                className="ig"
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="px-0" id="featured-3">
                    {Object.values(searchPayload).length > 0 &&
                      <div className="row all-filters d-flex">
                        {transformArrayFromObject(searchPayload).map((data, i) => {
                          if (data['min_salary'] || data['max_salary']) {
                            return null;
                          } else {
                            if (!isNullOrEmpty(getChipsName(data))) {
                              return <div key={i} className="col-2 f_t">
                                <p>{getChipsName(data)}</p>
                                <button className="del_jt" onClick={() => handleRemoveChips(data)}>
                                  <img src="../../Assets/svgs/x-grey.svg" alt="cross" />
                                </button>
                              </div>
                            }
                          }
                        })}
                      </div>}

                    <div className="tab-content" id="nav_tabContent_1">
                      <div
                        className="tab-pane fade show active"
                        id="list_1_content"
                        role="tabpanel"
                        aria-labelledby="list_1"
                      >
                        <div className=" px-0" id="featured-3">
                          <div
                            style={{
                              marginTop: "1rem",
                            }}
                            className="cards__container"
                          >
                            {referralJobs && referralJobs.length > 0
                              ? referralJobs.map((jobData, key) => {
                                return (
                                  <div key={key} className="card__hover main-card">
                                    <div
                                      onClick={() =>
                                        navigateJobDetail(jobData)
                                      }
                                      className=" hand-hover"
                                    >
                                      <div className="job__card justify-content-between box-hover">
                                        <div className="image_container">
                                          <img
                                            className="featured__img"
                                            src={jobData?.company?.logo}
                                            alt=""
                                          />
                                          {jobData.referral &&
                                            jobData.referral.length > 0 ? (
                                            <img
                                              src={referredJobImg}
                                              alt=""
                                              title={getReferralJobTooltip()}
                                            ></img>
                                          ) : null}
                                        </div>

                                        <div className="job__content">
                                          <div className="card-head">
                                            <div className="title">
                                              <p
                                                className="paragraph_1 truncate-2"
                                                title={jobData.title}
                                                dangerouslySetInnerHTML={{
                                                  __html: highlightText(
                                                    keyword,
                                                    jobData.title
                                                  ),
                                                }}
                                              ></p>
                                            </div>
                                          </div>

                                          <p className="paragraph_2 truncate-1">
                                            {jobData?.job_type?.name} .
                                            {getCandidateExperienceText(
                                              jobData.minimum_experience_required
                                            )}
                                            -
                                            {getCandidateExperienceText(
                                              jobData.maximum_experience_required
                                            )}
                                          </p>
                                          <p className="paragraph_3 d-flex gap-1 my-2">

                                            <span className="map-pin">
                                              <img src={mapPin} alt="" />
                                            </span>
                                            <span
                                              className="truncate-1"
                                              title={
                                                jobData?.locations.length > 0
                                                  ? jobData?.locations[0].name
                                                  : "India"
                                              }
                                            >
                                              {jobData?.locations.length > 0
                                                ? jobData?.locations[0].name
                                                : "India"}
                                            </span>
                                          </p>
                                        </div>

                                        <div
                                          className="footer"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <p
                                            className="paragraph_3_name truncate-1"
                                            title={jobData?.company?.name}
                                            style={{ fontWeight: 600 }}
                                          >
                                            {jobData?.company?.name}
                                          </p>
                                          <p
                                            className="paragraph_4 truncate-1"
                                            title={jobData.updated_date}
                                          >
                                            {jobData.updated_date}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                              : null}
                          </div>

                          <div
                            style={{
                              marginTop: "1rem",
                            }}
                            className="cards__container"
                          >
                            {featuredData.data.map((jobData, key) => {
                              return (
                                <div key={key} className="card__hover main-card">
                                  <div
                                    onClick={() => navigateJobDetail(jobData)}
                                    className=" hand-hover"
                                  >
                                    <div className="job__card justify-content-between box-hover">
                                      <div position="relative" className="pb-1">
                                        <div className="image_container">
                                          <img
                                            className="featured__img"
                                            src={
                                              jobData?.company_logo
                                                ? jobData?.company_logo
                                                : "/Assets/images/no-img.png"
                                            }
                                            alt=""
                                          />
                                          {jobData.referral &&
                                            jobData.referral.length > 0 ? (
                                            <img
                                              src={referredJobImg}
                                              alt=""
                                              title={getReferralJobTooltip()}
                                            ></img>
                                          ) : null}
                                        </div>

                                        <div
                                          style={{
                                            maxWidth: "80%",
                                          }}
                                          className="job__content"
                                        >
                                          <div className="card-head">
                                            <div className="title">
                                              <p
                                                className="paragraph_1 truncate-2"
                                                title={jobData.title}
                                                dangerouslySetInnerHTML={{
                                                  __html: highlightText(
                                                    keyword,
                                                    jobData.title
                                                  ),
                                                }}
                                              ></p>
                                            </div>
                                          </div>

                                          <p className="paragraph_2 truncate-1">
                                            {jobData.job_types} .
                                            {getCandidateExperienceText(
                                              jobData.minimum_experience_required
                                            )}
                                            -
                                            {getCandidateExperienceText(
                                              jobData.maximum_experience_required
                                            )}
                                          </p>
                                          <p className="paragraph_3 d-flex gap-1 my-2">

                                            <span className="map-pin">
                                              <img src={mapPin} alt="" />
                                            </span>
                                            <span
                                              className="truncate-1"
                                              title={
                                                jobData?.job_locations
                                                  ? jobData.job_locations
                                                  : "India"
                                              }
                                              dangerouslySetInnerHTML={{
                                                __html: highlightText(
                                                  keyword,
                                                  jobData?.job_locations
                                                    ? jobData.job_locations
                                                    : "India"
                                                ),
                                              }}
                                            ></span>
                                          </p>
                                        </div>

                                        {isShowPercentage &&
                                          (!jobData?.is_featured ||
                                            searchPayload.skills.length > 0) ? (
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "10px",
                                              right: "10px",
                                              width: "50px",
                                              height: "50px",
                                            }}
                                          >
                                            <div className="circle-rating">
                                              <CircularProgressbar
                                                value={getPercentage(
                                                  jobData.profile_percentage
                                                )}
                                                text={`${getPercentage(
                                                  jobData.profile_percentage
                                                )}%`}
                                                styles={buildStyles({
                                                  height: 40,
                                                  textColor: "#263238",
                                                  pathColor: getBarColor(
                                                    getPercentage(
                                                      jobData.profile_percentage
                                                    )
                                                  ),
                                                  trailColor: "#E3E3E3",
                                                })}
                                              />
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                      {jobData?.is_featured ? (
                                        <div className="bookmark">
                                          <CustomTooltip text="Featured Job">
                                            <IconResource type="bookmark" color="var(--red)" />
                                          </CustomTooltip>
                                        </div>
                                      ) : null}

                                      <div
                                        className="footer"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p
                                          className="paragraph_3_name truncate-1"
                                          title={jobData.company_name}
                                          style={{ fontWeight: 600 }}
                                          dangerouslySetInnerHTML={{
                                            __html: highlightText(
                                              keyword,
                                              jobData.company_name
                                            ),
                                          }}
                                        ></p>
                                        {/* <p><DoneAllIcon /></p> */}
                                        <p
                                          className="paragraph_4 truncate-1"
                                          title={jobData.updated_date}
                                        >
                                          {jobData.updated_date}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade common_items"
                        id="list_2_content"
                        role="tabpanel"
                        aria-labelledby="list_2"
                        style={{
                          overflowX: "scroll",
                        }}
                      >
                        <table className="table job-list-table">
                          <thead>
                            <tr>
                              <th scope="col">Job</th>
                              <th scope="col">Skills</th>
                              <th scope="col">Location</th>
                              <th scope="col">Company</th>
                              <th scope="col">Job Type</th>
                              <th scope="col">Experience</th>
                              {isShowPercentage ? (
                                <th scope="col">Matching Percentage</th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>
                            {referralJobs && referralJobs.length > 0
                              ? referralJobs.map((data, key) => {
                                return (
                                  <tr
                                    key={key}
                                    className="hover position-relative"
                                    onClick={() => navigateJobDetail(data)}
                                  >
                                    <td scope="col" className="bold">
                                      <p
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                        }}
                                      >
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: highlightText(
                                              keyword,
                                              data.title
                                            ),
                                          }}
                                        ></span>
                                        {data.referral &&
                                          data.referral.length > 0 ? (
                                          <img
                                            title={getReferralJobTooltip()}
                                            src={referredJobImg}
                                            alt=""
                                          ></img>
                                        ) : null}
                                      </p>
                                    </td>
                                    <td scope="col">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            data?.required_skills !== null &&
                                              data?.required_skills !==
                                              undefined &&
                                              data.required_skills.length > 0
                                              ? highlightText(
                                                keyword,
                                                data?.required_skills
                                                  .map(
                                                    (skill) => skill.name
                                                  )
                                                  .toString()
                                                  .split(",")
                                                  .join(", ")
                                              )
                                              : "N/A",
                                        }}
                                      ></p>
                                    </td>
                                    <td scope="col">
                                      {data?.locations !== null &&
                                        data?.locations !== undefined &&
                                        data.locations.length > 0
                                        ? data?.locations
                                          .map((location) => location.name)
                                          .toString()
                                          .split(",")
                                          .join(", ")
                                        : "India"}
                                    </td>
                                    <td scope="col">{data?.company?.name}</td>
                                    <td scope="col">
                                      {data?.job_type?.name}
                                    </td>
                                    <td scope="col">
                                      {getCandidateExperienceText(
                                        data.minimum_experience_required
                                      )}
                                      -
                                      {getCandidateExperienceText(
                                        data.maximum_experience_required
                                      )}
                                    </td>
                                    {isShowPercentage ? (
                                      <td scope="col">
                                        {getPercentage(
                                          data.profile_percentage
                                        )}
                                        %
                                      </td>
                                    ) : null}
                                    {data?.is_featured ? (
                                      <td
                                        colSpan={2}
                                        className="bookmark"
                                        style={{
                                          right: "-12px",
                                          top: "-10px",
                                        }}
                                      >
                                        <CustomTooltip text="Featured Job">
                                          <IconResource type="bookmark" color="var(--red)" />
                                        </CustomTooltip>
                                      </td>
                                    ) : null}
                                  </tr>
                                );
                              })
                              : null}
                            {featuredData?.data?.map((data, key) => {
                              return (
                                <tr
                                  key={key}
                                  className="hover position-relative"
                                  onClick={() => navigateJobDetail(data)}
                                >
                                  <td scope="col" className="bold">
                                    <p
                                      style={{
                                        display: "flex",
                                        gap: "5px",
                                      }}
                                    >
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: highlightText(
                                            keyword,
                                            data.title
                                          ),
                                        }}
                                      ></span>
                                      {data.referral &&
                                        data.referral.length > 0 ? (
                                        <img
                                          title={getReferralJobTooltip()}
                                          src={referredJobImg}
                                          alt=""
                                        ></img>
                                      ) : null}
                                    </p>
                                  </td>
                                  <td scope="col">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          data?.job_skills !== null &&
                                            data?.job_skills !== undefined &&
                                            data.job_skills.length > 0
                                            ? highlightText(
                                              keyword,
                                              data.job_skills
                                                .split(",")
                                                .join(", ")
                                            )
                                            : "N/A",
                                      }}
                                    ></p>
                                  </td>
                                  <td scope="col">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: highlightText(
                                          keyword,
                                          data.job_locations !== null
                                            ? data.job_locations
                                              .split(",")
                                              .join(", ")
                                            : "India"
                                        ),
                                      }}
                                    ></p>
                                  </td>
                                  <td scope="col">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: highlightText(
                                          keyword,
                                          data.company_name
                                        ),
                                      }}
                                    ></p>
                                  </td>
                                  <td scope="col">{data.job_types}</td>
                                  <td scope="col">
                                    {getCandidateExperienceText(
                                      data.minimum_experience_required
                                    )}
                                    -
                                    {getCandidateExperienceText(
                                      data.maximum_experience_required
                                    )}
                                  </td>
                                  {isShowPercentage ? (
                                    <td scope="col">
                                      {getPercentage(data.profile_percentage)}%
                                    </td>
                                  ) : null}
                                  {data?.is_featured ? (
                                    <td
                                      colSpan={2}
                                      className="bookmark"
                                      style={{ right: "-12px", top: "-10px" }}
                                    >
                                      <CustomTooltip text="Featured Job">
                                        <IconResource type="bookmark" color="var(--red)" />
                                      </CustomTooltip>
                                    </td>
                                  ) : null}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div>
                      {/* <p className="page-size-info">Showing {featuredData.from} - {featuredData.to}</p> */}

                      <div className="my-3">
                        {
                          <CustomPagination
                            activePage={activePage}
                            currentPage={setActivePage}
                            onPageChange={handlePaginationChange}
                            totalData={featuredData?.total}
                            pageLength={featuredData?.per_page}
                            lastPage={featuredData?.last_page}
                            tag="pages"
                            isPaywallActiveCancel={isPaywallActiveCancel}
                            dataFrom={featuredData.from}
                            dataTo={featuredData.to}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="result_feed">
                  <div className="result_header">
                    <h2 className="topTitle">Search result</h2>
                    <div className="result_header_sort">
                      <p>Sort by:</p>
                      <div className="dropdown">
                        <a
                          className="btn btn-secondary dropdown-toggle"
                          href={undefined}
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {searchPayload.orderByText}
                        </a>

                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.NEWEST], orderByText: [ORDER_BY_TEXT.NEWEST] });
                                setOrderByUser(true);
                              }}
                              href={undefined}
                            >
                              Newest First
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.OLDEST], orderByText: [ORDER_BY_TEXT.OLDEST] });
                                setOrderByUser(true);
                              }}
                              href={undefined}
                            >
                              Oldest First
                            </a>
                          </li>
                          {isShowPercentage ? (
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.RELEVANT], orderByText: [ORDER_BY_TEXT.RELEVANT] });
                                  setOrderByUser(true);
                                }}
                                href={undefined}
                              >
                                Most Relevant
                              </a>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                      <div className="filter_tab_header">
                        <div
                          className="list-group"
                          id="list-tab-1"
                          role="tablist"
                        >
                          <a
                            className="list-group-item list-group-item-action active"
                            id="list_1"
                            data-bs-toggle="list"
                            href="#list_1_content"
                            role="tab"
                            aria-controls="list_1_content"
                          >
                            <div className="round">
                              <img
                                onError={commonServices.imgError}
                                src="/Assets/svgs/list_view_icon.svg"
                                alt=""
                                className="ig"
                              />
                            </div>
                          </a>
                          <a
                            className="list-group-item list-group-item-action"
                            id="list_2"
                            data-bs-toggle="list"
                            href="#list_2_content"
                            role="tab"
                            aria-controls="list_2_content"
                          >
                            <div className="round">
                              <img
                                onError={commonServices.imgError}
                                src="/Assets/svgs/grid_view_icon.svg"
                                alt=""
                                className="ig"
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" px-0 " id="featured-3">
                    <div className="" id="featured-3" style={{ marginTop: "0px" }}>
                      <div className="row all-filters d-flex ">
                        {Object.values(searchPayload).length > 0 &&
                          transformArrayFromObject(searchPayload).map((data, i) => {
                            if (data['min_salary'] || data['max_salary']) {
                              return null;
                            } else {
                              if (!isNullOrEmpty(getChipsName(data))) {
                                return <div key={i} className="col-2 f_t">
                                  <p>{getChipsName(data)}</p>
                                  <button className="del_jt" onClick={() => handleRemoveChips(data)}>
                                    <img src="../../Assets/svgs/x-grey.svg" alt="cross" />
                                  </button>
                                </div>
                              }
                            }
                          })}
                      </div>
                    </div>
                  </div>

                  <div className="blank_content d-flex">
                    {noResultErr ? (
                      <>
                        <img
                          onError={commonServices.imgError}
                          src={emptyResult}
                          alt=""
                        />
                        <h2>Sorry we couldn't find any matches</h2>
                        <p>
                          No records found. Please retry with other search
                          parameters
                        </p>
                        <div
                          className="form_buttons"
                          style={{ marginTop: "2em" }}
                        >
                          <Button
                            buttonType="danger"
                            onClick={(e) => {
                              clearFilters(e);
                            }}
                            text="Clear"
                          />
                          <Button
                            buttonType="secondary"
                            onClick={() => {
                              window.history.back();
                            }}
                            text="Back"
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Job Search Filter Drawer -- Starts  */}

          <button
            className={"drawer__toggle-btn"}
            onClick={() => {
              setDrawerIsOpen(!drawerIsOpen);
            }}
          >
            {/* Drawer Toggle Button */}
            <Text
              text="FILTER YOUR RESULTS"
              style={{
                fontWeight: 700,
                wordSpacing: "8px",
              }}
            />
          </button>

          <div
            className={`drawer__wrapper ${drawerIsOpen ? "show" : ""}`}
            direction="right"
            style={{ right: drawerIsOpen ? 0 : "-100%" }}
          >
            {/* Drawer */}
            <div
              className="drawer__overlay"
              style={{ inset: drawerIsOpen ? 0 : "auto" }}
              onClick={() => {
                setDrawerIsOpen(!drawerIsOpen);
              }}
            ></div>
            <div
              class="drawer"
              style={{
                width: drawerIsOpen ? "400px" : "0px", // width: {drawerIsOpen ? ["300px", "400px"] : "0px"}
                // width: {drawerIsOpen ? ["300px", "400px"] : "0px"}
              }}
            >
              <div className="drawer__header">
                <h2>Filter Search </h2>
                <div className="drawer__header-clear">
                  <button className="clearBtn" onClick={(e) => clearFilters(e)}>
                    Clear All
                  </button>

                  <RxCross2
                    style={{
                      cursor: "pointer",
                    }}
                    color="var(--green)"
                    size={20}
                    className="closeBtn"
                    onClick={() => {
                      setDrawerIsOpen(!drawerIsOpen);
                    }}
                  />
                </div>
              </div>
              <div className="drawer__body">
                <form className="result_filter" id="result_filter">
                  <div className="filter-category d-flex flex-column">
                    <div className="filter_title-wrapper">
                      <p>Preferred Job Location</p>
                    </div>

                    <div>
                      <div className="filter-search">
                        <input type="text" placeholder="City" id="search_location_filter" onChange={(e) => handleDisplayLocationChange(e.target.value)} />
                      </div>

                      {displayLocations.arr?.length > 0 ? (
                        (displayLocations.arr || [])
                          .sort((a, b) => {
                            return searchPayload.locations.includes(b.id) - searchPayload.locations.includes(a.id);
                          })
                          .slice(0, 10)
                          .map((loc, i) => (
                            <div key={i} className="checkbox_div_c">
                              <input
                                type="checkbox"
                                name=""
                                id={`loc_checked_c_d${i}`}
                                checked={searchPayload.locations.includes(loc.id)}
                                onChange={(e) => {
                                  manageDataInPayload('locations', loc.id, e.target.checked ? 'a' : 'r');
                                  handleDisplayLocationChange('');
                                  document.getElementById('search_location_filter').value = '';
                                }}
                              />
                              <label className="form-label" htmlFor={`loc_checked_c_d${i}`}>
                                {loc.name}, {loc.description}
                              </label>
                            </div>
                          ))
                      ) : <em>Try Searching different Location!</em>}

                      {displayLocations.count > 10 &&
                        <div className="more" >
                          <p className="hand-hover" onClick={() => { setShowModalLocation(true); setDrawerIsOpen(false); }}>+{displayLocations.count - 10} More
                          </p>
                        </div>}
                    </div>
                  </div>

                  <div className="filter-category d-flex flex-column">
                    <div className="filter_title-wrapper"><p>Offered Salary</p></div>
                    <div>
                      {(masterData?.salary_range || [])
                        .sort((a, b) => searchPayload.salary_id.includes(b.id) - searchPayload.salary_id.includes(a.id))
                        .map((s, i) => {
                          return <div key={i} className="checkbox_div">
                            <input type="checkbox" name="" id={`salary_range_checked_${i}`} checked={searchPayload.salary_id.includes(s.id)} onChange={(e) => manageDataInPayload('salary_id', s.id, e.target.checked ? 'a' : 'r')} />
                            <label className="form-label" htmlFor={`salary_range_checked_${i}`}>{CURRENCY} {s.name}</label>
                          </div>
                        })}
                    </div>
                  </div>

                  <div className="filter-category leftJobTypeTab d-flex flex-column">
                    <div className="filter_title-wrapper">
                      <p>Preferred Job Type</p>
                    </div>
                    <div>
                      {(masterData?.job_types || [])
                        .sort((a, b) => searchPayload.job_types.includes(b.id) - searchPayload.job_types.includes(a.id))
                        .map((jt, i) => {
                          return <div key={i} className="checkbox_div">
                            <input type="checkbox" name="" id={`job_type_checked_${i}`}
                              checked={searchPayload.job_types.includes(jt.id)}
                              onChange={(e) => manageDataInPayload('job_types', jt.id, e.target.checked ? 'a' : 'r')}
                            />
                            <label className="form-label" htmlFor={`job_type_checked_${i}`}>{jt.name}</label>
                          </div>
                        })}
                    </div>
                  </div>

                  <div className="filter-category leftJobTypeTab d-flex flex-column">
                    <div className="filter_title-wrapper">
                      <p>Joining Preferences</p>
                    </div>
                    <div>
                      {(masterData?.joining_preferences || [])
                        .sort((a, b) => searchPayload.preferences.includes(b.id) - searchPayload.preferences.includes(a.id))
                        .map((preference, i) => {
                          return <div key={i} className='checkbox_div'>
                            <input type="checkbox" name="" id={`joining_preferences_checked_${i}`} checked={searchPayload.preferences.includes(preference.id)} onChange={(e) => manageDataInPayload('preferences', preference.id, e.target.checked ? 'a' : 'r')} />
                            <label className="form-label" htmlFor={`joining_preferences_checked_${i}`}>{preference.name}</label>
                          </div>
                        })}
                    </div>
                  </div>

                  <div className="filter-category leftJobTypeTab d-flex flex-column">
                    <div className="filter_title-wrapper">
                      <p>Job Durations</p>
                    </div>
                    <div>
                      {(masterData?.job_durations || [])
                        .sort((a, b) => searchPayload.durations.includes(b.id) - searchPayload.durations.includes(a.id))
                        .map((duration, i) => {
                          return <div key={i} className='checkbox_div'>
                            <input type="checkbox" name="" id={`job_durations_checked_${i}`} checked={searchPayload.durations.includes(duration.id)} onChange={(e) => manageDataInPayload('durations', duration.id, e.target.checked ? 'a' : 'r')} />
                            <label className="form-label" htmlFor={`job_durations_checked_${i}`}>{duration.name}</label>
                          </div>
                        })}
                    </div>
                  </div>

                  <div className="filter-category leftJobTypeTab d-flex flex-column">
                    <div className="filter_title-wrapper">
                      <p>Salary Types</p>
                    </div>
                    <div>
                      {(masterData?.salary_types || [])
                        .sort((a, b) => searchPayload.salary_types.includes(b.id) - searchPayload.salary_types.includes(a.id))
                        .map((st, i) => {
                          return <div key={i} className='checkbox_div'>
                            <input type="checkbox" name="" id={`salary_types_checked_${i}`} checked={searchPayload.salary_types.includes(st.id)} onChange={(e) => manageDataInPayload('salary_types', st.id, e.target.checked ? 'a' : 'r')} />
                            <label className="form-label" htmlFor={`salary_types_checked_${i}`}>{st.name}</label>
                          </div>
                        })}
                    </div>
                  </div>

                  <div className="filter-category leftJobTypeTab d-flex flex-column">
                    <div className="filter_title-wrapper"><p>Industry Domains</p></div>

                    <div>
                      <div className="filter-search">
                        <input type="text" placeholder="Search Industry Domain" id="search_industry_domains_filter" onChange={e => handleDisplayDomainsChange(e.target.value)} />
                      </div>

                      {displayDomains?.arr.length > 0 ? (
                        (displayDomains.arr || [])
                          .sort((a, b) => {
                            return searchPayload.domains.includes(b.id) - searchPayload.domains.includes(a.id);
                          })
                          .slice(0, 10)
                          .map((domain, i) => (
                            <div key={i} className="checkbox_div_c">
                              <input
                                type="checkbox"
                                name=""
                                id={`domains_checked_c_d${i}`}
                                checked={searchPayload.domains.includes(domain.id)}
                                onChange={(e) => {
                                  manageDataInPayload('domains', domain.id, e.target.checked ? 'a' : 'r');
                                  handleDisplayDomainsChange('');
                                  document.getElementById('search_industry_domains_filter').value = '';
                                }}
                              />
                              <label className="form-label" htmlFor={`domains_checked_c_d${i}`}>
                                {domain.name}
                              </label>
                            </div>
                          ))
                      ) : <em>Try Searching different domain!</em>}

                      {displayDomains.count > 10 &&
                        <div className="more" >
                          <p className="hand-hover" onClick={() => { setShowDomainsModal(true); setDrawerIsOpen(false); }}>+{displayDomains.count - 10} More
                          </p>
                        </div>}
                    </div>
                  </div>

                  <div className="filter-category d-flex flex-column">
                    <div className="filter_title-wrapper">
                      <p>Total Experience</p>
                    </div>

                    <div className="flex-column mt-2">
                      <MultiRangeSlider
                        min={initialPayload.min_exp[0]}
                        max={initialPayload.max_exp[0]}
                        defaultValue={{ min: searchPayload.min_exp[0], max: searchPayload.max_exp[0] }}
                        resetSlider={resetSlider}
                        onChange={({ min, max }) => {
                          if (searchPayload.min_exp[0] !== min) {
                            manageDataInPayload('min_exp', min, '');
                          } else if (searchPayload.max_exp[0] !== max) {
                            manageDataInPayload('max_exp', max, '');
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="filter-category d-flex flex-column">
                    <div className="filter_title-wrapper"><p className="s_heading">Impacted By Layoff
                      <label className="switch">
                        <input type="checkbox" checked={searchPayload.eeo[0] === '1'} onChange={(e) => manageDataInPayload('eeo', e.target.checked ? '1' : '0')} />
                        <span
                          className="slider round"
                        ></span>
                      </label>
                    </p>
                    </div>
                    <div className="filter_title-wrapper"><p className="s_heading">Her Career Reboot
                      <label className="switch">
                        <input type="checkbox" checked={searchPayload.eeo[0] === '1'} onChange={(e) => manageDataInPayload('eeo', e.target.checked ? '1' : '0')} />
                        <span
                          className="slider round"
                        ></span>
                      </label>
                    </p>
                    </div>
                    <div className="filter_title-wrapper"><p className="s_heading">Differetly Abled
                      <label className="switch">
                        <input type="checkbox" checked={searchPayload.eeo[0] === '1'} onChange={(e) => manageDataInPayload('eeo', e.target.checked ? '1' : '0')} />
                        <span
                          className="slider round"
                        ></span>
                      </label>
                    </p>
                    </div>
                    <div className="filter_title-wrapper"><p className="s_heading"> Armed forces
                      <label className="switch">
                        <input type="checkbox" checked={searchPayload.eeo[0] === '1'} onChange={(e) => manageDataInPayload('eeo', e.target.checked ? '1' : '0')} />
                        <span
                          className="slider round"
                        ></span>
                      </label>
                    </p>
                    </div>
                    <div className="filter_title-wrapper"><p className="s_heading"> Travel Readiness
                      <label className="switch">
                        <input type="checkbox" checked={searchPayload.eeo[0] === '1'} onChange={(e) => manageDataInPayload('eeo', e.target.checked ? '1' : '0')} />
                        <span
                          className="slider round"
                        ></span>
                      </label>
                    </p>
                    </div>
                  </div>

                  <div className="filter-category d-flex flex-column">
                    <div className="filter_title-wrapper">
                      <p className="s_heading">Travel Required</p>
                    </div>

                    <div className="d-flex flex-column">
                      <input
                        type="range"
                        className="form-range"
                        min="0"
                        max="100"
                        step="25"
                        id="customRange3"
                        value={searchPayload.travel_percent}
                        onChange={(e) => {
                          if (searchPayload.travel_percent !== e.target.value) {
                            manageDataInPayload('travel_percent', e.target.value, '');
                          }
                        }}
                      ></input>
                      <div className="range_label">
                        <div className="left_value">0%</div>
                        <div className="left_value">25%</div>
                        <div className="left_value">50%</div>
                        <div className="left_value">75%</div>
                        <div className="right_value">100%</div>
                      </div>
                    </div>
                  </div>

                  <div className="filter-category d-flex flex-column">
                    <div className="filter_title-wrapper"><p className="s_heading">Equal Opportunity Employer
                      <label className="switch">
                        <input type="checkbox" checked={searchPayload.eeo[0] === '1'} onChange={(e) => manageDataInPayload('eeo', e.target.checked ? '1' : '0')} />
                        <span
                          className="slider round"
                        ></span>
                      </label>
                    </p>
                    </div>
                  </div>

                  <div className="filter-category d-flex flex-column">
                    <div className="filter_title-wrapper">
                      <p>Jobs Posted Between</p>
                    </div>

                    <DateRangePicker startDate={searchPayload.start_date[0]} setStartDate={(type, date) => manageDataInPayload(type, date, 'a')} endDate={searchPayload.end_date[0]} setEndDate={(type, date) => manageDataInPayload(type, date, 'a')} datePickerStartRef={datePickerStartRef} datePickerEndRef={datePickerEndRef} isDateFilter={isDateFilter} setIsDateFilter={setIsDateFilter} showDateShortcuts={true} onChange={(start, end) => {
                      manageDataInPayload('dates', [start, end], '');
                    }} usedInSearchComp={true} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MaxWidthContainer >
    </>
  );
}

export default Jobs;
