export const genderOptions = [
    { id: '', name: 'Please select gender' },
    { id: 'Male', name: 'Male' },
    { id: 'Female', name: 'Female' },
    { id: 'Other', name: 'Other' },
    { id: 'Prefer Not to Disclose', name: 'Prefer Not to Disclose' },
];

export const companySizeOptions = [
    { id: 'Prefer Not to Disclose', name: 'Prefer Not to Disclose' },
    { id: 'Small', name: 'Small' },
    { id: 'Medium', name: 'Medium' },
    { id: 'Large', name: 'Large' },
    { id: 'Any', name: 'Any' },
];