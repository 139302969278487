export const DATA = "master_data";
export const GUEST_USER = "guest";
export const AUTH = 'auth';
export const PROFILE = 'profile';
export const SKILLS = 'skills';
export const INDUSTRY_DOMAIN = 'industry_domains';
export const USER_ROLE = 'user_role';
export const YEARS_TOTAL = 40;
export const APP_NAME = "TrueTalent";
export const APP_URL = "TrueTalent.io";
export const CURRENCY = "₹";
export const LOCATION_THRESHOLD = 2;
export const INDUSTRY_DOMAIN_THRESHOLD = 1;
export const SKILL_THRESHOLD = 1;
export const CANDIDATES_PER_PAGE_LIMIT = 48;
export const CURRENCY_FORMAT = "en-IN";
export const PREFIX_CANDIDATE_ID = "TT1000";
export const SESSION = "session";
export const LOCAL = "local";
export const COUNTRY = "IN";
export const APPLICANTS = "applicants";
export const LOCATIONS = 'locations';
export const BUDGET_LIMIT = 9999999;
export const BUDGET_LIMIT_TEXT = '99,99,999';
export const REMOTE = 'Remote';
export const NO_RESULT_ON_FILTER = 'Based on your selected criteria of ';